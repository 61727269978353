import styled from 'styled-components';

export default styled.div`
    .container{
        text-align:center;
        font-size:2vw;
        @media(min-width:775px){
            font-size:${(775*.02)/16}rem;
        }

        .title{
            font-family:'Avenir Next Bold',sans-serif;
        }
        .headline{
            font-family:'Avenir Next Bold',sans-serif;
        }
        .venue{
            font-size:1.2em;
        }
        .status{

        }
        .start{

        }
        .end{

        }
        .address{
            a{
                color:inherit;
            }
        }
        .duties{

        }
        .spots{

        }
    }
`