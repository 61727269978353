import styled from 'styled-components';
const dropdownHeight = 25;
export default styled.div`
    @import url('https://fonts.googleapis.com/css?family=Beth+Ellen|Pacifico|Permanent+Marker&display=swap');
    height:100%;
    position:relative;
    .container{
        padding:2vw;
        display:flex;
        flex-direction:column;
        align-items:center;

        .tagline{
            padding:2vw;
            margin-top:2vw;
            background-color:black;
            color:white;
            text-align:center;
            font-size:3vw;
            width:100vw;
            .title{
                text-align:center;
                font-size:5vw;
                font-family: 'Baloo Bhai', Sans-Serif;
            }
            .mission{
            };
        }
        .signed-by{
            margin:2vw 0 ;
            text-align:center;
            font-family: 'Baloo Bhai', Sans-Serif;
            font-size:4vw;
        }
        h1{
            text-align:center;
            font-size:7vw;
            margin:2vw 0 .25rem 0;
            font-family: 'Special Elite', Sans-Serif;
        }
        .category-1{
            color:#00aeef;
        }
        .category-2{
            color:#2e3192;
        }
        .category-3{
            color:#f69600;
        }
        .category-4{
            color:#808080;
        }
        ul{
            display:flex;
            list-style:none;
            justify-content:space-between;
            flex-wrap: wrap;
            padding:0 0;
            margin:0 0;
            align-content:flex-basis;
            li{
                position:relative;
                display:flex;
                flex-direction:column;
                .name{
                    -webkit-user-select: none;
                    -moz-user-select: -moz-none;
                    -ms-user-select: none;
                        user-select: none;
                    white-space: nowrap;
                    margin:0 1vw;
                    &.pointer{
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    &.team{
                        font-family: 'Permanent Marker', Sans-Serif;
                        font-size:3vw;
                        margin-top:.75vw;
                    }
                    &.small{
                        font-size:1vw;
                        margin-top:2vw;
                    }
                    &.med{
                        font-size:1.5vw;
                        margin-top:.5vw;
                    }
                    &.large{
                        font-size:2vw;
                    }
                    &.pacifico{
                        font-family: 'Pacifico', Sans-Serif;
                    }
                    &.beth-ellen{
                        font-family: 'Beth Ellen', Sans-Serif;
                    }
                    &.permanent-marker{
                        font-family: 'Permanent Marker', Sans-Serif;
                    }

                }
                .info-buffer{
                    position:relative;
                    height:${dropdownHeight}vw;
                    max-height:0px;
                    width:100%;
                }

                .info-box{
                    max-height: 0px;
                    max-width:0px;
                    width:100vw;
                    height:${dropdownHeight}vw;
                    top:0;
                    overflow-x:hidden;
                    /* left:-${(props) => {
                        return props.offSet || 0}
                        }vw; */
                    overflow-x:hidden;
                    overflow-y:hidden;
                    position: absolute;
                    .shadow {
                        position: absolute;
                        width: 120vw;
                        height: ${dropdownHeight}vw;
                        box-shadow: inset 0 0 .5vw .5vw rgba(0,0,0,0.25);
                        top: 0;
                        left: -10%;
                        pointer-events: none;
                    }

                    .info-container{
                        display:flex;
                        .img{
                            img{
                                height:${dropdownHeight}vw;
                                display: block;
                            }
                        }
                        .content{
                            /* border-top:0px; */
                            padding:1vw;
                            font-size:1vw;
                            .fullName{
                                font-size:2em;
                            }
                            .title{
                                font-size:1.5em;
                            }
                            .city-state{
                                font-size:1.25em;
                            }
                            .bio{
                                font-size:1em;
                            }
                        }
                    }
                }

                &.show{
                    .info-buffer{
                        max-height: 500px;
                        transition: max-height 1s ease-in;

                    }
                    .info-box{
                        max-width:100vw;
                        max-height: 500px;
                        transition: max-height 1s ease-in;
                    }
                }
                &.hide{
                    .info-buffer{
                        max-height: 0px;
                        transition: max-height .5s ease-out;

                    }
                    .info-box{
                        max-height: 0px;
                        max-width:0px;
                        transition: max-height .5s ease-out,
                                    max-width .1s ease-out
                    }
                }
            }
        }
    }
`