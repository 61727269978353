import styled from 'styled-components';
import { media , screenSizes} from 'app/components/mediaObject'

export const StyledRotatingBeneficiary = styled.div`
    margin:2vw 0;
    width:100%;
    display:flex;
    flex-direction:column;
    .img-container{
        img{
            width:100%;
            box-shadow: 0 0 5px 10px rgba(0, 0, 0, .4);
            margin:1rem 0;
        }
    }


    .about{
        margin:0 2em;
        h1{
            font-size:7vw;
            font-family: 'Special Elite', Sans-Serif;
            margin:2vw 0 0 0;
        }
    }
    ${media.tablet`
            flex-direction:${({reverse})=>reverse? 'row-reverse' : 'row'};
            .img-container{
                flex:4;
            }
            .about{
                flex:6;
                h1{
                    font-size: ${screenSizes.tablet*.1/16}rem;
                }
            }
        `}
    ${media.fullhd`
        max-width:${screenSizes.fullhd/16}rem;
        margin:2rem auto;
        h1{
            font-size: ${screenSizes.fullhd*.1/16}rem;
        }
    `}
`