import { css } from "styled-components";
//mobile first approach min-width
export const screenSizes = {
    fullhd: 1408,
    widescreen: 1215,
    desktop: 1023,
    tablet: 600,
    mobile: 0
  }
export const media = Object
      .keys(screenSizes)
      .reduce((acc, label) => {
          acc[label] = (...args) => css`
              @media (min-width: ${screenSizes[label] / 16}rem) {
                  ${css(...args)}
              }
          `
          return acc
      }, {});