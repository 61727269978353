import { SET_CART, SET_CART_STATUS, CLEAR_SHIPPING, UPDATE_CART } from './types';

export const setCart = (payload) => dispatch => {
    dispatch({ type: CLEAR_SHIPPING, payload:[] })
    dispatch({ type: SET_CART, payload })
};
export const updateCart = (payload) => dispatch => {
    dispatch({ type: CLEAR_SHIPPING, payload:[] })
    dispatch({ type: UPDATE_CART, payload })
};
export const setCartStatus = (payload) => (
    { type: SET_CART_STATUS, payload }
);