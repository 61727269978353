import React from 'react';
import { connect } from 'react-redux';
import SpinningLogo from 'app/components/reusable/SpinningLogo';
import StyledEventInfo from './Styled';

import formatDate from 'app/helperJS/formatDate';
import convertMarkup from 'app/helperJS/convertMarkup';
import EventSignup from './EventSignup';
import MyHelmet from 'app/MyHelmet';

const EventInfo = (props) => {
	if (props.events.notLoaded) return <SpinningLogo />
	const openArray =  ['Organizational Period','Ready','In Progress']
	const event = props.events.eventList.find(e=> Number(e.item_id) === Number(props.id))

	event.openStatus = openArray.find(s=> s === event.status) ? true : false;
	return(
		<StyledEventInfo>
			 <MyHelmet
				pageTitle={event.title}
				desc={convertMarkup(event.duties)}
				imgPath='/imgs/openGraphLogo.png'
			/>
			<div className="container">
				<h2 className="title">{event.title}</h2>
				<div className="status">{event.status}</div>
				<h3 className="headline">When</h3>
				<div className="start">Starts: {formatDate(event.starts, 'datetime')}</div>
				<div className="end">Ends: {formatDate(event.ends, 'datetime')}</div>
				<h3 className="headline">Where</h3>
				<div className="venue">{event.venue}</div>
				<div className="address">
					<a href={`https://www.google.com/maps/place/${event.address}`} target="_blank" rel="noopener noreferrer">{event.address}</a>
				</div>
				<h3 className="headline">Who</h3>
				<div className="spots">{event.openStatus
					? `${event.spotsAvailable} Spots Remaining`
					:`${event.spotsTotal} Volunteers + Team Leader Participated`
				}</div>
				<h3 className="headline">What</h3>
				<div className="duties">{convertMarkup(event.duties)}</div>
				{event.openStatus && <EventSignup eventid={event.item_id}/>}
			</div>
		</StyledEventInfo>
	)
}

const connectRedux = connect(state=>state)
export default connectRedux(EventInfo);