import React from 'react';
import { connect } from 'react-redux';
import StyledEvents from './StyledComponents';
import MyGoogleMap from 'app/components/MyGoogleMap';
import EventsList from './EventsList';
import EventInfo from './EventInfo';
import Modal from 'app/components/reusable/Modal';
import history from 'app/history';
import MyHelmet from 'app/MyHelmet';


class Events extends React.Component {

    state={
        bounds:{notLoaded:true},
        coords:[],
        modal:false
    }

    toggleModal = () => {
		let modal = this.state.modal;
		modal = !modal
        this.setState({modal})
        history.push('/events')
    };

	renderModal=(id)=>{
		return(
			<Modal
				id={`eventModal`}
				isOpen={this.state.modal}
				onClose={this.toggleModal}
				onSubmit={(v) => console.error(v)}
				title={`Event Info`}
			>
                <EventInfo id={id}/>
			</Modal>
		)
	}

    render(){
        return(
            <StyledEvents>
                <MyHelmet
					pageTitle='Events'
					desc='Events List and Map, Apply for and upcomeing event today!'
					imgPath='/imgs/openGraphLogo.png'
				/>
                <div className="container">
                    <h1>Events</h1>
                    <MyGoogleMap
                        coords={this.props.events.notLoaded ? [] : this.props.events.coords}
                        onBoundsChanged={(bounds) => this.setState({bounds})}
                        icon='/imgs/triLogoSmall.png'
                    />
                    <div className='list-container'>
                        <h1>Event List</h1>
                        {this.props.events.notLoaded &&
                            <div style={{textAlign:'center', fontSize:'1.5em', padding:'1rem 0' }}>
                                (Events loading in background)
                            </div>}
                        <EventsList
                            bounds={this.state.bounds}
                            onCoordsChanged={(coords) => this.setState({coords})}
                            coords={this.state.coords}
                        />
                    </div>
                    {this.props.match.params.id && this.renderModal(this.props.match.params.id)}
                </div>
            </StyledEvents>
        )
    }
}
export default connect(state=>state)(Events)