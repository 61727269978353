import React, {useState , useEffect} from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import usePrevious from 'app/helperJS/customHooks/usePrevious'
import * as actions from 'app/redux/actions';
const withScript = scriptLoader('https://www.paypalobjects.com/api/checkout.js')

const PaypalButton = ({isScriptLoaded, isScriptLoadSucceed , cart, cartTotal, shippingCost, currency, env, commit, client, onSuccess, onError, onCancel , setCartStatus}) => {
    const [showButton, setShowButton] = useState(false)
    const previous = usePrevious({showButton , isScriptLoaded, isScriptLoadSucceed})
    useEffect(()=>{
        window.React = React;
        window.ReactDOM = ReactDOM;
    },[])

    useEffect(()=>{
        if(isScriptLoaded && isScriptLoadSucceed){
            if (!previous || (!previous.showButton && !previous.isScriptLoaded)){
                setShowButton(true);
            }
        }

    },[previous, isScriptLoaded, isScriptLoadSucceed])

    const items = Object.entries(cart)
        .reduce((acc,[sku,{qtys,title,cost}]) => (
            acc.concat(Object.entries(qtys)
                .map(([size,quantity])=>({
                    name: title,
                    description:size,
                    quantity:String(quantity),
                    price: String(cost),
                    tax: "0",
                    sku,
                    currency: "USD"
                }))
            )
        ),[])

    const paymentRequest = {
        transactions: [{
            amount: {
                total:cartTotal+shippingCost,
                currency,
                "details": {
                    "subtotal": String(cartTotal),
                    "tax": "0",
                    "shipping": String(shippingCost),
                    "shipping_discount": "0",
                    "insurance": "0"
                }
            },
            "item_list": {
                items
            }
        }]
    }

    const payment = async () => {
        setCartStatus('authorizing')
        const response = await window.paypal.rest.payment.create(env, client, paymentRequest);
        return response
    }
    const onAuthorize = (data, actions) =>{
        actions.payment.execute()
            .then(() => {
                const reciept = {
                    paid: true,
                    cancelled: false,
                    payerID: data.payerID,
                    paymentID: data.paymentID,
                    paymentToken: data.paymentToken,
                    returnUrl: data.returnUrl,
                };
                onSuccess(reciept);
            })
    }

    if(!showButton) return <div/>
    const {Button} = window.paypal

    return(
        <Button.react
            env={env}
            client={client}
            commit={commit}
            payment={payment}
            onAuthorize={onAuthorize}
            onCancel={onCancel}
            onError={onError}
            style= {{
                size: 'responsive',
                color: 'black',
                shape: 'rect',
                label: 'checkout',
                tagline: 'false'
            }}
        />
    );
}
export default withScript(connect(({cart})=>({cart}),actions)(PaypalButton));