import React from 'react';
import { connect } from 'react-redux';
import formatMoney from 'app/helperJS/formatMoney';
import StyledBeneficiaries from './StyledComponents';
import MyHelmet from 'app/MyHelmet';

class Beneficiaries extends React.Component {
    renderList(){
        let responseObjects = [];
        let totalDonated = 0;

        this.props.beneficiaries && this.props.beneficiaries.forEach(beneficiary=>{
            let name="", website="", totalBeneficiaryDonations=0;
            beneficiary.fields.forEach(field =>{
                switch(field.external_id){
                    case 'title':
                        return name = field.values[0].value
                    case 'website':
                        return website = field.values[0].embed.resolved_url
                    case 'total-donated':
                        totalBeneficiaryDonations = Number(field.values[0].value)
                        return totalDonated += totalBeneficiaryDonations
                    default:
                        return
                }
            })
            totalBeneficiaryDonations > 0 && responseObjects.push(
                {name, website, totalBeneficiaryDonations}
            )
        })
        responseObjects.sort((a,b)=> b.totalBeneficiaryDonations - a.totalBeneficiaryDonations)
        const beneficiaryArray = responseObjects.map(({name, website, totalBeneficiaryDonations})=>{
           if(!website) return (
                <tr key={name + Math.random()}>
                    <td className="name">&#183; {name}</td>
                    <td className="donations">${formatMoney(totalBeneficiaryDonations)}</td>
                </tr>
            )
            return  (
                <tr key={name + Math.random()}>
                    <td className="name">&#183; <a href={website} rel="noopener noreferrer" target="_blank">{name}</a></td>
                    <td className="donations">${formatMoney(totalBeneficiaryDonations)}</td>
                </tr>
            )
        })
        return {totalDonated , beneficiaryArray}
    }

    render(){
        const list = this.props.beneficiaries.notLoaded ? {notLoaded:true} : this.renderList();
        return(
            <StyledBeneficiaries>
                <MyHelmet
					pageTitle='Beneficiaries'
					desc='All of our past beneficiaries and how much has been raised'
					imgPath='/imgs/openGraphLogo.png'
				/>
                <div className="container">
                    <h1>Beneficiaries</h1>
                    {list.notLoaded
                        ? <img src="../imgs/spinning_aoc_large.gif" alt="loading"/>
                        : <>
                            <h3>Total Donated to Beneficiaries: ${formatMoney(list.totalDonated)}</h3>
                            <table>
                                <tbody>{list.beneficiaryArray}</tbody>
                            </table>
                        </>
                    }
                </div>
            </StyledBeneficiaries>
        )
    }
}

export default connect(state=>state)(Beneficiaries);