import styled from 'styled-components';

export const StyledNavSideBar = styled.div`

	.background {
		display:${({display}) => display || `none`};
		position: fixed;
		background: rgba(0, 0, 0, 0.5);
		z-index: 1040;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		outline: 0;

		opacity: 0;
		&.fade-in {
			opacity: 1;
			transition: opacity linear ${({transitionInTime})=> transitionInTime || '0.15'}s;
		}
		&.fade-out {
			opacity: 0;
			transition: opacity linear ${({transitionOutTime})=> transitionOutTime || '0.15'}s;
		}
	}

	.nav-sidebar {
		position: fixed;
		z-index: 1050;
		left: 0;
		top: 0;
		width: 250px;
		height: 100%;
		overflow-y:auto;
		display: grid;
		display:${({display}) => display || `none`};
		grid-template-rows:auto 1fr auto;
		grid-template-columns:100%;
		grid-template-areas:'header'
							'links'
							'footer';
		padding:24px;
		background-color: #fefefe;
		box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);

		opacity: 0;
		&.fade-in {
			opacity: 1;
			transition: opacity linear ${({transitionInTime})=> transitionInTime || '0.15'}s;
		}
		&.fade-out {
			opacity: 0;
			transition: opacity linear ${({transitionOutTime})=> transitionOutTime || '0.15'}s;
		}

		.nav-links {
			grid-area:links;
			overflow-y:auto;
			height:100%;

			.nav-links-list{
				margin: 0;
				padding: 0;
				display:flex;
				flex-direction:column;
				justify-content:space-between;

				.nav-links-main{
					width:100%;
					display:flex;
					flex-direction:column;
					justify-content:space-between;
					align-items:center;
				}
				.nav-links-signIn{
					width:100%;
					display:flex;
					flex-direction:column;
					justify-content:space-between;
					align-items:center;
				}

				.nav-links-item{
					margin: 5px 0;
					height:100%;
					width:80%;
					text-align:center;
					color:#838383;

					.nav-link{
						color:inherit;
						display:block;
						text-decoration: none;
						font-weight: bold;
						padding: 3px 0;
						height:100%;
						width:100%;
						/* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); */
					}
				}
			}
		}
		.nav-header {
			grid-area:header;
			display:flex;
			align-items:center;
			justify-content:center;
			padding-bottom:16px;
			border-bottom: 1px solid #c7c7c7;
			.nav-title {
				text-align:center;
				text-decoration: none;
				margin: 0 0 0 0;
				font-size: 2rem;
				font-weight: 400;

				a{
					text-decoration: none;
					color:inherit;
				}
			}
		}
		.nav-footer {
			grid-area:footer;
			display:flex;
			align-items:center;
			justify-content:center;
			border-top: 1px solid #c7c7c7;
		}
	}
`;