import React from 'react';
import { connect } from 'react-redux';
import { StyledStore } from './StyledComponents';
import MyHelmet from 'app/MyHelmet';

import MoreInfoModal from './MoreInfoModal';

import * as actions from 'app/redux/actions';
import RotatingBeneficiaries from './RotatingBeneficiaries';
import InventorySection from './InventorySection';
import Checkout from './Checkout';
import { statusTextObj } from '../../../helperJS/statusTextObj';
import { RotatingBeneficiaryInfoIcon } from './StyledComponents';
import { FreeRaffleSignup } from './FreeRaffleSignup';

const Store = ({
  inventory,
  rotatingBeneficiary,
  cart,
  cartStatus,
  match: { params, path },
}) => {
  if (inventory.notLoaded || rotatingBeneficiary.notLoaded) return null;
  const storeType = path.split('/')[1];
  const storeTitle = storeType
    .toLowerCase()
    .replace(/\b(\w)/g, (s) => s.toUpperCase());
  const rotatingBenePluralSinglular =
    rotatingBeneficiary.length === 1
      ? 'organization/cause'
      : 'organizations/causes';

  return (
    <StyledStore>
      <MyHelmet
        pageTitle={storeTitle + '!'}
        desc='Art and Merch Store'
        imgPath='/imgs/openGraphLogo.png'
      />
      {Object.keys(cart).length > 0 && <Checkout checkoutButtonAsModal />}
      {params.item_id && (
        <MoreInfoModal
          storeType={storeType}
          item={inventory.find((item) => item.item_id === params.item_id)}
        />
      )}

      <h1>{storeTitle}</h1>
      <br />
      {storeType === 'store' && (
        <>
          <div>
            A portion of every donation for items with the{' '}
            <RotatingBeneficiaryInfoIcon style={{ cursor: 'auto' }}>
              RB
            </RotatingBeneficiaryInfoIcon>{' '}
            icon will go directly to the {rotatingBenePluralSinglular} below!
          </div>
          <RotatingBeneficiaries />
        </>
      )}
      <h2>{statusTextObj[cartStatus]}</h2>
      {cartStatus.err && (
        <div className='cart-error'>Error: {cartStatus.err}</div>
      )}
      {(cartStatus === 'ready' || cartStatus.err) && (
        <>
          <InventorySection storeType={storeType} inventory={inventory} />
        </>
      )}
      {storeType === 'raffles' && (
        <FreeRaffleSignup/>
      )}
    </StyledStore>
  );
};

export default connect(
  (
    { inventory, raffles, rotatingBeneficiary, cart, cartStatus },
    { match: { path } }
  ) => ({
    inventory: path.includes('store') ? inventory : raffles,
    rotatingBeneficiary,
    cart,
    cartStatus,
  }),
  actions
)(Store);
