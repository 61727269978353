import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeFreeRaffle } from 'app/redux/actions';
const FreeRaffleSignupDumb = (props) => {
  const [email, setEmail] = useState('');
  const [eventCode, setEventCode] = useState('');
  return (
    <div>
      <h2 style={{ paddingTop: 10 }}>Free Raffle Signup</h2>
      <h3 style={{ marginTop: 0 }}>
        <div>We will have drawings throughout the year!</div>
        <div>
          Only one free entry per email, purchase more raffle tickets for a
          better chance to win!
        </div>
      </h3>
      <div>
        <div>
          Email:{' '}
          <input
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
          />
        </div>
        <div>
          Event:{' '}
          <input
            value={eventCode}
            onChange={({ target: { value } }) => setEventCode(value)}
          />
        </div>
        <div>
          <button onClick={() => props.makeFreeRaffle({ email, eventCode })}>
            Submit
          </button>
        </div>
      </div>
      {props.freeRaffleStatus && (
        <h3 style={{ marginTop: 0, color: 'red' }}>{props.freeRaffleStatus}</h3>
      )}
    </div>
  );
};

export const FreeRaffleSignup = connect(
  ({ freeRaffle }) => ({ freeRaffleStatus: freeRaffle.status }),
  { makeFreeRaffle }
)(FreeRaffleSignupDumb);
