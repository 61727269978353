import React from 'react';
const convertMarkup = (s) => {
	const string = [];
	s.split('<br />').forEach(frag1 =>{
		if (frag1 !== '<br />'){
			frag1.split('<p>').forEach(frag2 =>{
				frag2.split('</p>').forEach(frag3 =>{
					const frag4 =frag3
						.replace(/&nbsp;/g,' ')
						.replace(/&amp;/g,'&')
						.replace('<p dir="ltr">','')
					string.push(<span key={Math.random()}> {frag4} <br/> </span>)
				})
			})
		}
	})
	return string
}

export default convertMarkup