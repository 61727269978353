import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import formatDate from 'app/helperJS/formatDate';

class List extends React.Component {
    render(){
        const {neLat, neLng, swLat, swLng} = this.props.bounds
        const events = [];
        // const coords = []
        let status, minVolSpts;
        if(this.props.registrationFilter){
            status = ['Organizational Period','Ready','In Progress']
            minVolSpts = 1
        } else {
            status = ['Organizational Period','Ready','In Progress','Past Event']
            minVolSpts = 0
        }

        this.props.events.eventList.forEach(event=>{
            const eventResponseObject = {latVerified:false , lngVerified:false , statusVerified:false, spotsVerified:false }

            if(event.latitude >= swLat || event.latitude <= neLat)
                eventResponseObject.latVerified = true;
            if(event.longitude <= neLng || event.longitude >= (swLng < neLng ? swLng : -180.000))
                eventResponseObject.lngVerified = true;
            if(status.find(s => s === event.status))
                eventResponseObject.statusVerified = true;
            if (event.spotsAvailable >= minVolSpts)
                eventResponseObject.spotsVerified = true;
            eventResponseObject.date = formatDate(event.starts, 'date')
            if (eventResponseObject.latVerified && eventResponseObject.lngVerified && eventResponseObject.statusVerified && eventResponseObject.spotsVerified) {

                eventResponseObject.jsx = <li key={event.item_id + Math.random()}>
                    <Link to={`/events/${event.item_id}`}>
                        <span className='date'>{eventResponseObject.date}</span> {event.title}
                    </Link>
                </li>
                events.push(eventResponseObject.jsx)
            }
        })
        return events.length === 0 ? "None Found Try a Different Search" : events;
    }
}
export default connect(state=>state)(List)