import { GET_SHIPPING , ADD_TRACKING } from './types';
import axios from 'axios';

export const getShipping = ({address , cart }) => async dispatch =>{
    try{
        const response  = await axios.post('/api/order/shipping-estimate',{address , cart})
        const rates = response.data.err ? [] : response.data.rates
        const shipmentId = response.data.err ? [] : response.data.shipmentId
        return dispatch({ type: GET_SHIPPING, payload: {shipmentId,rates, err:null} })
    }catch(err){
        console.error(err.response.data.err)
        return dispatch({ type: GET_SHIPPING, payload: {rates:[], err:err.response.data.err }})
    }
};

export const addTracking = (payload) => (
    { type: ADD_TRACKING, payload }
)