export const reduce = (
	{ cartTotal, cartQty, raffles },
	{ qtys, cost, storeType }
) => {
	const { addTotal, addQty, addRaffles } = Object.values(qtys)
    .reduce(({ addTotal, addQty, addRaffles }, size) => ({
      addTotal: addTotal + size * cost,
      addQty: addQty + size,
      addRaffles:
        storeType === 'raffles' ? addRaffles + size : addRaffles,
    }),{ addTotal: 0, addQty: 0, addRaffles: 0 });

	return {
		cartTotal: cartTotal + addTotal,
		cartQty: cartQty + addQty,
		raffles: raffles + addRaffles,
	};
};

export const getCartValues = (cart) => Object
	.values(cart)
	.reduce(reduce,{cartTotal:0,cartQty:0,raffles:0});
