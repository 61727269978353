import styled from 'styled-components';
import { media , screenSizes} from 'app/components/mediaObject'

export const RotatingBeneficiariesSection = styled.div`
    width:100%;
    border-top:1rem dotted black;
    border-bottom:1rem dotted black;
    margin:2rem 0;
    padding:2rem 0;
    .beneficiary-name{
        font-size:8vw;
    }
    ${media.tablet`
        .beneficiary-name{
            font-size: ${screenSizes.tablet*.08/16}rem;
        }
    `}
`