import React from 'react';
import { Link } from 'react-router-dom';

const NavLinks = (props) => {
	const links = {
		'Home': '/',
		'Events': '/events',
		'Get Involved': '/apply',
		'Beneficiaries': '/beneficiaries',
		'Dream Out Loud': '/dream-out-loud',
		'Walls To Windows': '/walls-to-windows',
		'Store': '/store',
		'Raffles': '/raffles',
		'Our Team': '/team',
		'Contact': '/contact',
	};

	const onMouseDown = (e) => {
		props.onCloseNav && props.onCloseNav(e);
	};
	return Object.keys(links).map((link) => {
		return (
			<div className='nav-links-item' key={link + links[link]}>
				<Link
					className='nav-link'
					to={links[link]}
					onMouseDown={onMouseDown}
				>
					{link}
				</Link>
			</div>
		);
	});
};

export default NavLinks;
