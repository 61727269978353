import React from 'react';
import { Helmet } from 'react-helmet';

export default ({pageTitle , desc , imgPath , children}) => {
    return (
        <Helmet>
            <title>{`Advocates of Change : ${pageTitle}`}</title>
            <meta property="og:site_name" content ="Advocates of Change" />
            <meta property="og:title" content ={pageTitle} />
            <meta property="og:description" content ={desc} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={typeof window === 'undefined' ? '' : window.location.href} />
            <meta property="og:image" content={imgPath} />
            {children}
        </Helmet>
    )
}