import styled from 'styled-components';
import {media , screenSizes} from 'app/components/mediaObject';

export const StyledStore = styled.div`
    text-align:center;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    h1{
        font-size:10vw;
        font-family: 'Special Elite', Sans-Serif;
        margin:2vw 0 0 0;
        ${media.tablet`
            font-size: ${screenSizes.tablet*.1/16}rem;
        `}
    }
    h2{
            font-family: 'Baloo Bhai', Sans-Serif;
            margin:0 0;
            line-height:1.5em;
    }
`

export const CheckOutButton = styled.button`
    height:4.50em;
    width:4.50em;
    padding:0;
    border-radius: 50%;
    position:fixed;
    bottom:2em;
    right:0;left:0;margin:0 auto;
    background-color: #7ACDF5;
    color:white;
    font-family: 'Baloo Bhai', Sans-Serif;
    text-align:center;
    /* text-shadow: rgba(100,100,100,.75) .25em 0 .5em; */
    box-shadow: 0 0 .5em .5em rgba(0,0,0,.2);
    border: .25em solid white;
    font-size:2em;
    z-index:2000;
    cursor: pointer;
`

export const RotatingBeneficiaryInfoIcon = styled.div`
    text-align: center;
    border-radius:100%;
    overflow:hidden;
    height:7vw;
    width:7vw;
    display:inline-flex;
    justify-content: center;
    align-content: center;
    vertical-align: middle;
    font-family: 'Baloo Bhai', Sans-Serif;
    font-size:4.5vw;
    background-color:gold;
    cursor: help;

    ${media.tablet`
        font-size:2vw;
        width:3vw;
        height: 3vw;
    `}

${media.desktop`
        font-size:1rem;
        width:1.5rem;
        height:1.5rem;
    `}
`