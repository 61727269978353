import {GET_VOLUNTEERS, GET_TEAM} from '../actions/types';
const defaultState = {
    volunteers:{notLoaded:true},
    team:{notLoaded:true},
}
export default (state=defaultState,action)=>{
    switch(action.type){
        case GET_VOLUNTEERS:
            return {...state , volunteers: action.payload}
        case GET_TEAM:
            return {...state , team: action.payload}
        default:
            return state;
    }
}