import {GET_SHIPPING,CLEAR_SHIPPING,ADD_TRACKING} from '../actions/types';

export default (state={shipmentId:'',rates:[],error:null, tracking_number:'',tracking_url_provider:''},{type,payload})=>{
    switch(type){
        case GET_SHIPPING:
            return payload;
        case ADD_TRACKING:
            const {tracking_number,tracking_url_provider} = payload
            return {...state , tracking_number, tracking_url_provider};
        case CLEAR_SHIPPING:
                return {shipmentId:'',rates:[],error:null};
        default:
            return state;
    }
}