import './shared.css';

import React from 'react';
import { Router, Route , Switch , Redirect } from 'react-router-dom';
import history from 'app/history';

import { connect } from 'react-redux';
import {
	getEvents,
	getBeneficiaries,
	getVolunteers,
	getTeam,
	getInventory,
	getRaffles,
	getRotatingBeneficiary,
	getAwardees
} from 'app/redux/actions';

import NavMenu from './NavMenu';
import Footer from './Footer';

import Landing from './routes/Landing';
import Beneficiaries from './routes/Beneficiaries';
import Events from './routes/Events';
import Apply from './routes/Apply';
import Contact from './routes/Contact';
import DreamOutLoud from './routes/DreamOutLoud';
import Team from './routes/Team';
import Store from './routes/Store';
import WallsToWindows from './routes/WallsToWindows';

class App extends React.Component {
	componentDidMount(){
		this.props.getEvents();
		this.props.getBeneficiaries();
		this.props.getVolunteers();
		this.props.getTeam();
		this.props.getAwardees();
		this.props.getInventory();
		this.props.getRaffles();
		this.props.getRotatingBeneficiary();
	}

	render() {
		return (
			<Router history={history}>
				<div style={{display:"flex", flexDirection:'column', minHeight:'100vh'}}>
					<NavMenu />
					<div style={{flex:'1', position:'relative'}}>
						<Switch >
							<Route path="/" exact component={Landing} />
							<Route path="/beneficiaries" exact component={Beneficiaries} />
							<Route path="/events" exact component={Events} />
							<Route path="/events/:id" exact component={Events} />
							<Route path="/apply" exact component={Apply} />
							<Route path="/contact" exact component={Contact} />
							<Route path="/dream-out-loud" exact component={DreamOutLoud} />
							<Route path="/walls-to-windows" exact component={WallsToWindows} />
							<Route path="/team" exact component={Team} />
							<Route path="/store/" exact component={Store} />
							<Route path="/store/:item_id" exact component={Store} />
							<Route path="/raffles/" exact component={Store} />
							<Route path="/raffles/:item_id" exact component={Store} />

							{/* old route redirects */}
							<Redirect status={301} from='/#' to="/"/>
							<Route path='/event-map/' render={props=> <Redirect status={301} from='/event-map/' to="/events" props={props} />}/>
							<Route path='/get-involved-2/' render={props=> <Redirect status={301} from='/get-involved-2/' to="/apply" props={props} />}/>
							<Route path='/artist-gallery/' render={props=> <Redirect status={301} from='/artist-gallery/' to="/artists" props={props} />}/>
							<Route path='/dream-out-loud-fund/' render={props=> <Redirect status={301} from='/dream-out-loud-fund/' to="/dream-out-loud" props={props} />}/>
							<Route path='/merch/' render={props=> <Redirect status={301} from='/merch/' to="/store" props={props} />}/>
							<Route path='/our-team/' render={props=> <Redirect status={301} from='/our-team/' to="/team" props={props} />}/>
							<Route path='/our-programs-2/' render={props=> <Redirect status={301} from='/our-programs-2/' to="/propgrams" props={props} />}/>
							<Route path='/partners/' render={props=> <Redirect status={301} from='/partners/' to="/team" props={props} />}/>
							<Route path='/past-beneficiaries/' render={props=> <Redirect status={301} from='/past-beneficiaries/' to="/beneficiaries" props={props} />}/>
							<Route path='/contact-us/' render={props=> <Redirect status={301} from='/contact-us/' to="/contact" props={props} />}/>

							{/* catch all */}
							<Route path="/" component={Landing} />
						</Switch>
					</div>
					<Footer />
				</div>
			</Router>
		);
	}
}

export default connect(null, {getEvents,getBeneficiaries,getVolunteers,getTeam, getAwardees , getInventory ,getRotatingBeneficiary, getRaffles})(App);