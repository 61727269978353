import styled from 'styled-components';
export const Container = styled.div.attrs((props)=> ({...props}))`
    padding:2vw;
    display:grid;
    grid-gap: 10px;
    width:100vw;
    grid-template-areas:'img'
                        'name'
                        'content';
    justify-items: center;
    align-items: center;
    @media(min-width:1250px){
        max-width:2500px;
        grid-template-columns: 20% 20% 60%;
        grid-gap: 10px;
        grid-template-areas:'left name img content right';
    }
`
export const StyledContent = styled.div.attrs((props)=> ({...props}))`
    padding:2vw;
    grid-area:'content';
    width: 100%;
    text-align:left;
`
export const StyledImg = styled.img.attrs((props)=> ({...props}))`
   width:100%;
`
export const ImgContainer = styled.div.attrs((props)=> ({...props}))`
    grid-area:'img';
    width:100%;
`
export const Name = styled.h1.attrs((props)=> ({...props}))`
    /* white-space:nowrap; */
    padding:2vw;
    grid-area:'name'
`