import {SET_CART,UPDATE_CART} from '../actions/types';

export default (state={},{type,payload})=>{
    switch(type){
        case SET_CART:
            return payload;
        case UPDATE_CART:
            const {item,size,qty,storeType} = payload
            const itemId = item.item_id
            const stateItem = state[itemId]


            const cartItemQtys = stateItem && stateItem.qtys
                ? stateItem.qtys
                : {}

            const oldQty = cartItemQtys[size]
            if(qty < 0 && oldQty === undefined) {
                return state
            }
            if(qty < 0 && oldQty + qty < 0){
                const newCart = {...state}
                newCart[itemId].qtys[size] = 0
                return newCart
            }
            const qtys = cartItemQtys[size]
                ? { ...cartItemQtys , [size]: qty + cartItemQtys[size] }
                : { ...cartItemQtys , [size]: qty }
            const newItem = {
                ...stateItem,
                storeType,
                qtys,
                title:item.title,
                imageLink: item.imageLink,
                cost:item.cost
            }
            return {
                ...state,
                [itemId]:newItem
            }
        default:
            return state;
    }
}