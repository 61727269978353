import React from 'react';
import { StyledFooter } from "./StyledComponents"

const Footer = (props) => {

	const renderlinks = () => {
		const links = {
			facebook: {
				alt:'facebook logo',
				src:'/imgs/facebook.png',
				url:'https://www.facebook.com/ShareYourCloud/'
			},
			instagram: {
				alt:'instagram logo',
				src:'/imgs/instagram.png',
				url:'https://www.instagram.com/theadvocatesofchange/'
			},
		}
		return Object.keys(links).map(link=>{
			return(
				<div key={link + links[link]} className="footer-links-item" >
					<a className="footer-link" href={links[link].url} rel="noopener noreferrer" target="_blank">
						<img src={links[link].src} alt={links[link].alt} />
					</a>
				</div>
			)
		});
	}
	return (
		<StyledFooter
			id='footer'
		>
			<div className={`footer-menu`} >
				<div className="footer-links-list">
					<div className="links">
						{renderlinks()}
					</div>
					<div className="donation">
						<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
							<input type="hidden" name="cmd" value="_s-xclick" />
							<input type="hidden" name="hosted_button_id" value="YLG4LV84QHYDJ" />
							<input type="image" src="/imgs/paypal-donate.png" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
							<img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
						</form>
					</div>
				</div>
				<div className="footer-np">
					501(c)3 public charity non-profit organization <br/>
					Donations are tax-deductible! <br/>
					Thanks for the support! <br/>
					(EIN 81-1307461)
				</div>
				<div className="footer-webcreator">
					<div>Website designed and developed by <a className="footer-link" href='https://willman.codes' rel="noopener noreferrer" target="_blank">Willman.Codes</a></div>
				</div>
			</div>
		</StyledFooter>
	);
}
export default Footer;