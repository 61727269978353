import React from "react";
import config from './config';
import { useLoadScript } from '@react-google-maps/api'
import Map from './Map';

export default (props) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: config.googleLibraries
    })

    if (loadError) return <div> Map cannot be loaded right now, sorry.</div>
    return isLoaded ? <Map {...props} /> : <div/>;
}