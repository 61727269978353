import {GET_EVENTS} from '../actions/types';

export default (state={notLoaded:true},action)=>{
    switch(action.type){
        case GET_EVENTS:
                const eventList = action.payload
                const coords = []
                eventList.forEach(event=>{
                    const eventCoords = { coords: {
                            lat : Number(event.latitude),
                            lng:  Number(event.longitude)
                    }};
                    
                    if (event.status === 'Organizational Period' || 
                        event.status === 'Ready' || 
                        event.status === 'In Progress' || 
                        event.status === 'Past Event'
                    )coords.push(eventCoords)
                })
            return {eventList , coords}
        default:
            return state;
    }
}