import Styled from 'styled-components'

const break1 = 800;
const break1a = 1100;
const break1b = 1325;
const break2 = 1600;
const break3 = 2000;
const break4 = 2500;
const break5 = 2800;

const imgPercent = 83;
const imgDecimal = imgPercent/100;
const logoPercent = 27.5;
const logoDecimal = logoPercent/100;
const logoTop = 31;
const logoTopDecimal = logoTop/100;

const imgSplit1 = (break3-break1)*imgDecimal
const logoSplit1 = (break3-break1)*logoDecimal
const logoTopSplit1 = (break3-break1)*logoTopDecimal


export const StyledLanding = Styled.div`
    @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
    }
    .container{
        .section-one{
            background-color:white;
            text-align:left;
            display:flex;
            position:relative;

            @media(min-width:${break2}px){
                min-height:${break1*imgDecimal}px;
            }
            @media(min-width:${break4}px){
                min-height:868.83px;
            }
            .spinning-logo{
                position:absolute;
                width:${logoPercent}%;
                vertical-align:middle;
                transform-origin: 33% 50%;
                animation: rotation 15s infinite linear;
                top:${logoTop}vw;

                @media(min-width:${break1}px){
                    top:${break1*logoTopDecimal}px
                    width:${break1*logoDecimal}px;
                }
                @media(min-width:${break3}px){
                    width:calc(${logoPercent}% - ${logoSplit1}px);
                    top:calc(${logoTop}vw - ${logoTopSplit1}px);
                    max-width:10000px;
                }
                @media(min-width:${break4}px){
                    top:${(break4*logoTopDecimal) - logoTopSplit1}px;
                    width:${(break4*logoDecimal) - logoSplit1}px;
                }
            }
            .face-logo {
                height:${imgPercent}vw;
                vertical-align:middle;
                @media(min-width:${break1}px){
                    height:${break1*imgDecimal}px;
                }
                /* @media(min-width:${break2}px){
                    position: absolute;
                    left:0;
                } */
                @media(min-width:${break3}px){
                    position: static;
                    height:calc(${imgPercent}vw - ${imgSplit1}px);
                    max-width:10000px;
                }
                @media(min-width:${break4}px){
                    height:${(break4*imgDecimal) - imgSplit1}px;
                    max-width:10000px;
                }
                /* @media(min-width:${break5}px){
                    position: absolute;
                    left:0;
                } */
            }
            .tagline{
                display:flex;
                flex:1;
                align-items:center;
                justify-content:center;
                text-align:center;
                color:#000000;
                font-family: 'Special Elite', Sans-Serif;

                p{
                    font-size:6vw;
                    margin:4vw 0;
                    @media(min-width:${break1a}px){
                        margin:${(break1a * .04)}px 0;
                        font-size:${(break1a * .06)/16}rem;
                    }
                }

            }
            .desktop{
                display:none;
            }
            @media(min-width:${break1b}px){
                .mobile{
                    display:none;
                }
                .desktop{
                    font-size:5.5vw;
                    display:flex;
                }
            }
        }
        .section-two{
            background-color:black;
            padding: 2rem 0;

            .tagline{
                /* font-family: 'Special Elite', Sans-Serif; */
                text-align:center;
                font-size:3.5vw;
                color:white;
                @media(min-width:${break1b}px){
                    font-size:${(break1b*.04)/16}rem;
                }
                @media(min-width:${break2}px){
                    font-size:${(break2*.04)/16}rem;
                }
                h1{
                    margin:0 0 1vw 0;
                    font-family: 'Baloo Bhai', Sans-Serif;
                }

            }
        }
        .section-three{
            background-color:white;
            padding: 2rem 0;
            display:flex;
            justify-content:center;

            .tagline{
                width:70%
                text-align:justify;
                font-size:3vw;
                max-width:${(break1+200)/16}rem;
                @media(min-width:${break1b}px){
                    max-width:${(break1b+200)/16}rem;
                    font-size:${(break1b*.03)/16}rem;
                }
                @media(min-width:${break2}px){
                    max-width:${(break2+400)/16}rem;
                    font-size:${(break2*.02)/16}rem;
                }
                @media(min-width:${break3}px){
                    max-width:${(break3+500)/16}rem;
                    font-size:${(break3*.02)/16}rem;
                }
                @media(min-width:${break5}px){
                    max-width:${(break5+500)/16}rem;
                    font-size:${(break5*.02)/16}rem;
                }
                h1{
                    text-align:center;
                    margin:0;
                    font-family: 'Baloo Bhai', Sans-Serif;
                    font-size:8vw;
                    @media(min-width:${break1}px){
                        font-size:2em;

                    }
                }
                p{
                    text-indent:4vw;
                }

            }
        }
    }
`
