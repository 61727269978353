import React from 'react';
import StyledApplication from './StyledComponents';
import objShallowEqual from 'app/helperJS/objShallowEqual';
import MyHelmet from 'app/MyHelmet';

class Apply extends React.Component {
    state={
        iFrameHeight:0,
        iFrameRef:null
    }

    componentDidMount(){
        this.setState({iFrameRef:React.createRef()})
        window._podioWebForm.receiveMessage((e)=>{
            if (this.state.iFrameRef && this.state.iFrameRef.current && typeof e.data === 'string') {
                const iFrameHeight = e.data.split(':')[1]
                this.setState({iFrameHeight},()=>console.warn('(Ignore Style Error) Height managed manually: ', this.state.iFrameHeight))
            }
        },1289612)
    }
    shouldComponentUpdate(nextProps,nextState){
        return !objShallowEqual(this.state, nextState)
    }
    render(){
        return(
            <StyledApplication iFrameHeight={this.state.iFrameHeight}>
                <MyHelmet
					pageTitle='Apply'
					desc='Apply to become a volunteer'
					imgPath='/imgs/openGraphLogo.png'
				/>
                <div className="container">
                    <iframe
                        ref={this.state.iFrameRef}
                        title="get-involved-webform"
                        className="podio-webform-frame"
                        id="podioWebForm1289612778402"
                        src="https://podio.com/webforms/19156729/1289612?e=true#http%3A%2F%2Flocalhost%3A3000%2Fget-involved"
                    ></iframe>
                </div>
            </StyledApplication>
        )
    }
}

export default Apply