import { combineReducers } from "redux";
import beneficiariesReducer from './beneficiariesReducer';
import eventsReducer from './eventsReducer';
import mapsReducer from "./mapsReducer";
import crewReducer from "./crewReducer";
import inventoryReducer from "./inventoryReducer";
import raffleReducer from "./raffleReducer";
import awardeeReduceer from "./awardeeReduceer";
import rotatingBeneficiaryReducer from "./rotatingBeneficiaryReducer";
import cartReducer from "./cartReducer";
import cartStatusReducer from "./cartStatusReducer";
import shippingReducer from "./shippingReducer";
import freeRaffleReducer from "./freeRaffleReducer";

export default combineReducers({
    beneficiaries:beneficiariesReducer,
    events:eventsReducer,
    map:mapsReducer,
    crew:crewReducer,
    inventory:inventoryReducer,
    raffles:raffleReducer,
    freeRaffle: freeRaffleReducer,
    rotatingBeneficiary:rotatingBeneficiaryReducer,
    awardees:awardeeReduceer,
    cart:cartReducer,
    cartStatus:cartStatusReducer,
    shipping:shippingReducer
})