import React from 'react';
import {connect} from 'react-redux';
import StyledDreamOutLoud from './StyledComponents';
import {Link} from 'react-router-dom';
import formatMoney from 'app/helperJS/formatMoney';
import objShallowEqual from 'app/helperJS/objShallowEqual';
import MyHelmet from 'app/MyHelmet';
import Awardees from '../Awardees';


class DreamOutLoud extends React.Component {
    state={
        iFrameHeight:0,
        iFrameRef:null
    }

    componentDidMount(){
        this.setState({iFrameRef:React.createRef()})
        window._podioWebForm.receiveMessage((e)=>{
            if (this.state.iFrameRef && this.state.iFrameRef.current && typeof e.data === 'string') {
                const iFrameHeight = e.data.split(':')[1]
                this.setState({iFrameHeight},()=>console.warn('(Ignore Style Error) Height managed manually: ', this.state.iFrameHeight))
            }
        },1273031)
    }
    shouldComponentUpdate(nextProps,nextState){
        return !objShallowEqual(this.state, nextState)
    }
    renderTotals(){
        let dreamOutLoudDonations = 0;
        let benificiariesDonations = 0;

        this.props.beneficiaries && this.props.beneficiaries.forEach(beneficiary=>{
            beneficiary.fields.forEach(field =>{
                if(field.external_id === 'total-donated'){
                    if (beneficiary.title === 'Dream Out Loud'){
                        dreamOutLoudDonations += Number(field.values[0].value);
                    }else{
                        benificiariesDonations += Number(field.values[0].value);
                    }
                }
            })
        })
        return({dreamOutLoudDonations,benificiariesDonations})
    }
    render(){
        const totals = this.props.beneficiaries.notLoaded ? {notLoaded:true} : this.renderTotals();

        return(
            <StyledDreamOutLoud iFrameHeight={this.state.iFrameHeight}>
                <MyHelmet
					pageTitle='Dream Out Loud Program'
					desc='Advocates of Change program that seeks to uplift the Artists that support our vision.'
					imgPath='/imgs/openGraphLogo.png'
				/>
                <div className="container">
                    <div className="title">Dream Out Loud!</div>
                    <div className="tag-line">(Furthering the Arts One Dream at a Time)</div>
                    <div className="goal card">
                        <h2>Goal</h2>
                        <p>Every year the Dream Out Loud grant program aims to award four or more artists with funds to further their art and career development.</p>
                    </div>
                    <div className="why card">
                        <h2>Why</h2>
                        <p>Advocates of Change Artists have raised <Link to='/beneficiaries'>{totals.notLoaded ? '(Loading)' : '$' + formatMoney(totals.benificiariesDonations)}</Link> for charities nationwide!</p>
                        <p style={{fontSize:'.6em'}}>Plus an additional {totals.notLoaded ? '(Loading)' : '$' + formatMoney(totals.dreamOutLoudDonations)} specifically for this program!</p>
                        <br/>
                        <p>Art is the foundation of our community impact, we want to give back to those artists who make it all happen.</p>
                    </div>
                    <div className="who card">
                        <h2>Who</h2>
                        <h3>Artists of ANY Kind:</h3>
                        <i>Culinary</i>
                        <br/><i>Graffiti</i>
                        <br/><i>Inventor</i>
                        <br/><i>Software Engineer</i>
                        <br/><i>If you think its art we do too!</i>
                        <br/>
                        <br/><h3>Plus:</h3>
                        <i>Curators Hosting Art Shows</i>
                        <br/><i>Theatre &amp; Music Companies</i>
                        <br/><i>Emerging Arts Organizations</i>
                        <br/><i>Art Organizations Awaiting 501(c)3</i>
                    </div>
                    <div className="what card">
                        <h2>What</h2>
                            <h3>Enrichment of Your Art:</h3>
                                <p><i>Material, Production, &amp; Career Costs!</i></p>
                                <br/>
                            <h3>Self and Community Education:</h3>
                                <p><i>Tuition, Books, Seminars, &amp; Workshops!</i></p>
                                <br/>
                            <h3>Travel:</h3>
                                <p><i>Exhibitions, Galleries, &amp; Mission Trips!</i></p>
                    </div>
                    <div className="when card">
                        <h2>When</h2>
                            <h3>Deadlines:</h3>
                            Summer - June 15th
                            <br/>Winter - Dec 15th
                            <br/>(Recipients announced on the 30th)
                    </div>
                    <div className="how card">
                        <h2>How (much)</h2>
                            $2,000 grant
                            <br/>$1,000 grant
                            <br/>$500 grant
                            <br/>$200 grant
                    </div>
                    <div id='where' className="where card">
                        <h2>Where</h2>
                            <p>15% of every Fundraiser</p>
                            <br/>
                            <p>Dream Out Loud Specific Fundraisers</p>
                            <br/>
                            <p>PayPal donations specifying
                            <br/>"Dream Out Load" at checkout</p>
                    </div>
                    <div className='awardees'>
                        <h1 className='title'>
                            Past Awardees:
                        </h1>
                        <Awardees/>
                    </div>
                    <iframe
                        ref={this.state.iFrameRef}
                        title="dream-out-loud-webform"
                        className="podio-webform-frame"
                        id="podioWebForm1273031721263"
                        src="https://podio.com/webforms/18910582/1273031?e=true#http%3A%2F%2Ftheadvocatesofchange.com%2Fdream-out-loud-fund%2F"
                    ></iframe>
                </div>
            </StyledDreamOutLoud>
        )
    }
}

export default connect(state=>state)(DreamOutLoud);