import styled from 'styled-components';

const firstBreak = 420;
const maxWidth = 600;

export default styled.div`
    flex:1;
    display:flex;
    .container{
        flex:1;
        display:flex;
        flex-direction:column;
        align-items:center;
        margin:16px 0 0 0;
        h1{
            text-align:center;
            margin: 0 0;
            padding: 0 0 .25rem 0;
            @media(min-width:${firstBreak}px){
                font-size:2.5rem;
            }
            @media(min-width:${maxWidth}px){
                font-size:2.75rem;
            }
        }
        .podio-webform-frame{
            height:calc(${({iFrameHeight})=>iFrameHeight ? Number(iFrameHeight) : 1000}px + 3vw);
            width:100%;
            border:0px;
            outline:0px;
        }
    }
`