import React , {useState} from 'react';
import {Link} from 'react-router-dom';
import {StyledItemCard} from './StyledComponents';
import SelectASizeCartButtons from './SelectASizeCartButtons';

const ItemCard = ({ item , qtys, storeType }) => {
    const [hover , setHover]= useState(false)

    return(
        <StyledItemCard onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
            <div className='title'><Link className='link' to={`/${storeType}/${item.item_id}`}>{item.title}</Link></div>
            <Link className='link' to={`/${storeType}/${item.item_id}`}><img src={item.imageLink[0]} alt={item.title}/></Link>
            <SelectASizeCartButtons hover={hover} item={item} qtys={qtys} storeType={storeType} />
        </StyledItemCard>
    )
}

export default ItemCard