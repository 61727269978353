import React from 'react';
import { connect } from 'react-redux';
import StyledEventSignup from './Styled';
import eventSignup from './eventSignUp';

class EventSignup extends React.Component {
	state = {
		email: '',
		eventSignup: {status:'Not Started',error:'none'}
	}

	callback=eventSignup=>{
		this.setState({eventSignup})
		eventSignup.status === 'Application Submitted' && this.setState({email:''})
	}

	onSubmit=(e)=>{
		e.preventDefault();
		eventSignup(this.state.email, this.props.eventid, this.callback)
	}

	render(){
		return(
			<StyledEventSignup>
				<div className='hr'></div>
				<h3 className='title'>Event Signup</h3>
				<form onSubmit={this.onSubmit}>
					{this.state.eventSignup.error !== 'none' && <span className='error'>{this.state.eventSignup.error}</span>}
					{(this.state.eventSignup.status === 'Checking Email' || this.state.eventSignup.status === 'Submitting Application') && <span className='processing'>{this.state.eventSignup.status}</span>}
					{this.state.eventSignup.status === 'Application Submitted' && <span className='success'>{this.state.eventSignup.status}</span>}
					<div className='form-inputs'>
						<label className='label' htmlFor='email'>Email</label>
						<input className='email' onChange={(e)=>this.setState({email : e.target.value})} value={this.state.email} />
						{(this.state.eventSignup.status === 'Checking Email' || this.state.eventSignup.status === 'Submitting Application')
							? <img src="../imgs/spinning_aoc_large.gif" alt="loading"/>
							: <button className='submit' >Sign Up</button>
						}
					</div>
				</form>
			</StyledEventSignup>
		)
	}
}

const connectRedux = connect(state=>state)
export default connectRedux(EventSignup);