import styled from 'styled-components';

export default styled.ul`
    text-align:center;
    margin:0 0;
    padding:0 0;
    li{
        margin:1vw;
        list-style: none;
        a{
            font-size:1.5vw;
            list-style:none;
            text-decoration:none;
            color:inherit;
            &:hover{
                text-decoration:underline;
                font-size:1.75vw;
            }
            .date{
                font-family:'Avenir Next Bold',sans-serif;
            }
        }
    }

`