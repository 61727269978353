import React from 'react';
import { connect } from 'react-redux';
import Modal from 'app/components/reusable/Modal';
import { CheckoutFormContainer, SuggestedDonation } from './StyledComponents';
import * as actions from 'app/redux/actions';
import PayPalButton from './PaypalButton';
import OrderList from './OrderList';

const CheckoutModal = ({ cart, shippingCost, cartTotal, cartStatus, modal, setModal }) => {
  if(!cart) return null

  return <>
      <Modal
          shouldClose={true}
          isOpen={modal}
          onClose={()=>setModal(!modal)}
          title="Checkout"
          visibility={cartStatus === 'authorizing' ? 'hidden' : 'visible'}
      >
          <CheckoutFormContainer>
            <OrderList/>
            <SuggestedDonation>Suggested Donation: {'$' + cartTotal}</SuggestedDonation>
            <SuggestedDonation>Shipping: {'$' + shippingCost}</SuggestedDonation>
            <SuggestedDonation>Total: ${cartTotal + shippingCost}</SuggestedDonation>
            {cartStatus.err && <div className='cart-error'>Error: {cartStatus.err}</div>}
            <p>
              Please ensure correct contact/shipping information <br/> during PayPal Checkout
            </p>
            <PayPalButton setModal={setModal} cartTotal={cartTotal} shippingCost={shippingCost}/>
          </CheckoutFormContainer>
      </Modal>
  </>
}

export default connect(
  ({cartCustomer, cart, cartStatus})=>({cartCustomer, cart, cartStatus}),
  actions
)(CheckoutModal)