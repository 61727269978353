import styled from 'styled-components';
import {media , screenSizes} from 'app/components/mediaObject';

export const CheckoutFormContainer = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    text-align:center;
    padding-bottom:1.5em;

    h3{
            font-family: 'Baloo Bhai', Sans-Serif;
            margin:0 0;
            font-size:7vw;
    }
    li{
        padding:.1em 0;
        font-size:4vw;

    }
    form{
        display:grid;
        grid-template-columns: min-content 1fr;
        vertical-align: middle;
        input{
            margin:.5rem 0 .5rem .5rem;
            font-size:4vw;
        }
        label{
            display: flex;
            align-items: center;
            font-size:4vw;
            white-space: nowrap;
        }
    }
    button{
        font-size:4vw;
        color:white;
        background-color:black;
        font-family: 'Baloo Bhai', Sans-Serif;
        border-radius:1em;
        margin:1em;
        box-shadow: 0 0 .25em .1em rgba(0,0,0,0.25)
    }

    ${media.tablet`
        h2{
            font-size:${screenSizes.tablet*.09/16}rem;
        }
        h3{
            font-size:${screenSizes.tablet*.07/16}rem;
        }
        li,label,button{
            font-size:${screenSizes.tablet*.04/16}rem
        }
        form{
            input,label{
                font-size:${screenSizes.tablet*.04/16}rem
            }
        }

    `}

`

export const SuggestedDonation = styled.div`
    font-family: 'Baloo Bhai', Sans-Serif;
    margin:0 0;
    font-size:4.5vw;
    ${media.tablet`
        font-size:${screenSizes.tablet*.045/16}rem;
    `}
`