import Styled from 'styled-components'

export default Styled.div`
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    display:flex;
    justify-content:center;
    align-items:center;

    .spinning-logo{
        transform-origin: 33% 50%;
        animation: rotation 15s infinite linear;
        width:${100*.87}px;
        height:100px;
    }
`