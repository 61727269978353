import styled from 'styled-components';
// import {media , screenSizes} from 'app/components/mediaObject';

export const FilterSection = styled.div`
    width:100%;
    display:flex;
    justify-content:space-around;
    max-width:475px;
    label{
        display:block;
    }
    select{
        display: block;
        padding: .6rem 1.4rem .5rem .8rem;
        box-sizing: border-box;
        width:100%;
        border: 1px solid #aaa;
        border-radius: .5em;
        -moz-appearance: none;
        background-image: linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
        text-align-last:center;
    }
    div{
        flex:1;
        padding:0 1rem;
    }
`