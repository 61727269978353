import axios from 'axios';
import validateEmail from 'app/helperJS/validateEmail';

export default async (email,eventid,callback) =>  {
    if(email === '') return callback({status:'Not Started' , error:'No Email Provided'})
    if(!validateEmail(email)) return callback({status:'Not Started' , error:'Email Improper Format'})
    callback({status:'Checking Email',error:'none'})
    const res = await axios.get(`/api/checkemail/${email}`)
    submitApplication(email,eventid,callback,res.data)
};

const submitApplication = async (email,eventid,callback,count) => {
    if (count === 0) return callback({status:'Not Started' , error:'No Email Found'})
    callback({status:'Submitting Application',error:'none'})
    const res = await axios.post(`/api/submitapplication/${eventid}/${email}`)
    callback({ res: res.data, status:'Application Submitted',error:'none'})
}
