import React , {useState , useEffect} from 'react';
import { connect } from 'react-redux';
import {InventoryContainer} from './StyledComponents';
import ItemCard from './ItemCard';
import FilterSection from './FilterSection';
import * as actions from 'app/redux/actions'

const InventorySection = ({inventory,cart,storeType}) => {

    const [sortBy, setSortBy]= useState('title')
    const [artistFilter, setArtistFilter]= useState('none')
    const [typeFilter, setTypeFilter]= useState('none')
    const [artists, setArtists]= useState([])
    const [types, setTypes]= useState([])

    useEffect(()=>{
        if(inventory.notLoaded) return
        const setStateObjet = inventory.reduce((acc,item)=>{
            return {
                artists:{...acc.artists , [item.artistName]:1},
                types:{...acc.types , [item.category]:1}
            }
        },{artists:{},types:{}})
        setArtists(Object.keys(setStateObjet.artists))
        setTypes(Object.keys(setStateObjet.types))
    },[inventory])

    const sortInventory = (inventoryList) =>{
        let valA, valB;
        return inventoryList.sort((a,b)=>{
            if(typeof(a[sortBy]) === 'string'){
                valA = a[sortBy].toUpperCase();
                valB = b[sortBy].toUpperCase();
            }else{
                valA = a[sortBy];
                valB = b[sortBy];
            }
            if(valA === valB) return 0
            return valA > valB ? 1 : -1
        })
    }

    const filterInventory = (inventory) => {
        if(artistFilter === 'none' && typeFilter === 'none')
            return inventory
        return inventory.filter(item=> {
            if(artistFilter !== 'none' && typeFilter !== 'none'){
                return item.artistName === artistFilter && item.category === typeFilter
            }else if(artistFilter !== 'none'){
                return item.artistName === artistFilter
            }
            return item.category === typeFilter
        })
    }

    const renderInventory = (inventory) => {
        const filtered = filterInventory(inventory)
        const sorted = sortInventory(filtered)
        return sorted.map(item => <ItemCard
            storeType={storeType}
            key={item._id}
            qtys={cart[item.item_id] ? cart[item.item_id].qtys : 0}
            item={item}
        />)
    }

    return (!inventory || !inventory.length)
        ? <h1>Sorry Nothing for sale right now, check back later</h1>
        : <>
            <FilterSection setSortBy={setSortBy} setArtistFilter={setArtistFilter} setTypeFilter={setTypeFilter} setArtists={setArtists} setTypes={setTypes} sortBy={sortBy} artistFilter={artistFilter} typeFilter={typeFilter} artists={artists} types={types}/>
            <h2>All Prints are 11x17 unless otherwise noted</h2>
            <InventoryContainer>
                {renderInventory(inventory)}
            </InventoryContainer>
        </>
}

export default connect(
    ({cart})=>({cart}),
    actions
)(InventorySection)