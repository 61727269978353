const formatDate=(formatDate , output)=>{
	const d = new Date(formatDate)
	const date = new Date(Date.UTC( Number(d.getFullYear()), Number(d.getMonth()), Number(d.getDate()), Number(d.getHours()), Number(d.getMinutes())))
	const month = Number(date.getMonth())+1
	const day = date.getDate();
	const year = date.getFullYear();
	let hours;
	let timeofday;
	if (date.getHours() > 12){
		hours = date.getHours() < 22 ? "0" + (date.getHours() - 12) : date.getHours() - 12;
		timeofday = 'PM'
	} else {
		hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
		timeofday = 'AM'
	}
	const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

	switch(output){
    case 'datetime':
      return `${month}/${day}/${year} ${hours}:${minutes} ${timeofday}`
    case 'time':
      return `${hours}:${minutes} ${timeofday}`
    default:
      return `${month}/${day}/${year}`
  }
}

export default formatDate