import React from 'react';
import {Link} from 'react-router-dom';
import { StyledTopNav } from './StyledComponents'
import NavLinks from './NavLinks'
import NavSideBar from './NavSideBar';

const TopNav = (props) => {

	return (
		<StyledTopNav
			id='TopNav'
		>
			<div className={`nav-menu`} >
				<div className="nav-brand">
					<div className="nav-logo">
						<Link to='/'>
							<img src="/imgs/bannerLogo.jpg" alt="Banner Logo" />
						</Link>
					</div>
				</div>
				<div className="nav-links-list">
					<NavLinks />
				</div>
				<div className="nav-links-button">
					<NavSideBar/>
				</div>

			</div>
		</StyledTopNav>
	);
}
export default TopNav;