import {
  UPDATE_STATUS_CODE_FREE_RAFFLE,
} from '../actions/types';

export default (
  state = {
    status: null,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_STATUS_CODE_FREE_RAFFLE:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};
