import styled from 'styled-components';
import { media } from 'app/components/mediaObject'

export const StyledCartSection = styled.div`
    bottom:0;
    position:${({position})=> position || 'absolute'};
    width:100%;
    background-color:white;
    /* box-shadow: 0 -5px 0 5px rgba(0, 0, 0, .05); */
    border-top:.5px solid black;
    border-bottom: 2px solid black;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    font-family: 'Baloo Bhai', Sans-Serif;
    font-size:7vw;
    /* border:${({colapse})=>colapse?'':'1px solid black'}; */

    .label{
        font-size:.75em;
    }
    .quantity{
        font-size:.75em;
    }

    .cost{
        width:100%;
        text-align: center;
    }
    .cost-section{
        position: relative;
        width:100%;
        margin:3vw 0;
        display:flex;
        justify-content:space-between;
        align-items:center;

        .dropdown{
            cursor:pointer;
            margin:0 .7em;
        }
    }

    ${media.tablet`
        font-size:2vw;
        .cost-section{
            margin:.5rem .5rem;
        }
    `}

    ${media.desktop`
        font-size:1rem;
        visibility:${({hover,qty})=> hover || qty ? 'visibile' : 'hidden'};
    `}
`

export const SizeSection = styled.div`
        width:100%;
        background-color:white;
        /* box-shadow: 0 -5px 0 5px rgba(0, 0, 0, .05); */
        border-bottom:1px solid black;
        display:flex;
        justify-content:space-between;
        align-items:center;
        font-family: 'Baloo Bhai', Sans-Serif;
        font-size:7vw;


        ${media.tablet`
            font-size:2vw;
        `}

        ${media.desktop`
            font-size:1rem;
        `}
    `

export const CartButton = styled.span`
    background-color:${({color})=>color || 'gray'};
    text-align: center;
    border-radius:100%;
    overflow:hidden;
    height:7vw;
    width:7vw;
    margin: .5em .5em;
    display:flex;
    justify-content: center;
    align-content: center;
    pointer-events:none;
    i{
        font-size:5vw;
        margin:auto;
        pointer-events:auto;
        cursor:pointer;
    }

    ${media.tablet`
        height:3vw;
        width:3vw;
        i{
            font-size:2vw;
        }
    `}

    ${media.desktop`
        height:1.5rem;
        width:1.5rem;
        i{
            font-size:1rem;
        }
    `}
`