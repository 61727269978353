import styled from 'styled-components';
import {media , screenSizes} from 'app/components/mediaObject';

export const SectionTitle = styled.div`
    font-family: 'Baloo Bhai', Sans-Serif;
    margin:0 0;
    font-size:7vw;
    ${media.tablet`
        font-size:${screenSizes.tablet*.07/16}rem;
    `}
`

export const LineItem = styled.li`
    display:flex;
    img{
        align-self: center;
        width:50%;
    }
    .details{
        display:flex;
        flex-direction:column;
        align-content:center;
        justify-content:center;
        width:50%;
        .qty{
            font-size:.75em;
        }
    }
`