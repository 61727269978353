import React, {useState} from 'react';
import CheckoutModal from './CheckoutModal';
import {CheckOutModalButton, CheckOutButton} from './StyledComponents';
import { connect } from 'react-redux';
import { getCartValues } from 'app/helperJS/getCartValues';

const Checkout = ({cart, checkoutButtonAsModal = false, setModal: setParentModal}) => {
  const { cartTotal, cartQty, raffles } = getCartValues(cart)

  const shippingCost = Math.ceil((cartQty - raffles) / 4)*5
  const [modal , setModal]= useState(false)

  const setAllModals = (v) => {
    setModal(v);
    setParentModal && setParentModal(v);
  }

  const renderIncludeShipping = () => shippingCost
    ? <div style={{fontSize:'.5em'}}>incl. shipping</div>
    : <div/>;

  return <>

    {checkoutButtonAsModal
      ? <CheckOutModalButton onClick={()=>setAllModals(!modal)} >
          ${cartTotal + shippingCost} {renderIncludeShipping()}
        </CheckOutModalButton>
      : <CheckOutButton onClick={()=>setAllModals(!modal)}>
          Cart: ${cartTotal + shippingCost} {renderIncludeShipping()}
        </CheckOutButton>
    }
    {modal && <CheckoutModal
      cartTotal={cartTotal}
      shippingCost={shippingCost}
      modal={modal}
      setModal={setAllModals}
    />
  }
  </>

}

export default connect(
  ({cart}) => ({cart}),
  null
)(Checkout)