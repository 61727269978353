import styled from 'styled-components';

const containerMaxWidth = 1300;

export default styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    font-size:2vw;
    text-align: center;
    @media(min-width:${containerMaxWidth}px){
        font-size:${(containerMaxWidth*.02)/16}rem;
    }
    .container{
        max-width:${containerMaxWidth}px;
        h1{
            margin:1.5vw 0;
            line-height:1.2em;
        }
        .mural-heading{
            margin-top:2em;
        }
        h3{
            margin:0 0;
        }
        li{
            text-transform: capitalize;
        }
        p{
            margin:3vw 6vw;
            text-indent:4vw;
            text-align: justify;
            text-justify: inter-word;
            @media(min-width:${containerMaxWidth}px){
                text-indent:${(containerMaxWidth*.04)/16}rem;
            }
        }
        .photos{
            width: 87%
        }
        img{
            width:100%;
            vertical-align:middle;
        }
        .title{
            font-size:7vw;
            font-family: 'Special Elite', Sans-Serif;
            margin:2vw 0 0 0;
            @media(min-width:${containerMaxWidth}px){
                font-size:${(containerMaxWidth*.07)/16}rem;
                margin:${containerMaxWidth*.02}px 0 0 0;
            }
        }
        .quote{
            font-size:3vw;
            font-family: 'Special Elite', Sans-Serif;
            margin:2vw 10vw;
            @media(min-width:${containerMaxWidth}px){
                font-size:${(containerMaxWidth*.03)/16}rem;
            }
        }
        .thanks{
            font-size:2vw;
            margin:2vw 0 0 0;
            text-align:center;
            @media(min-width:${containerMaxWidth}px){
                font-size:${(containerMaxWidth*.02)/16}rem;
                margin:${containerMaxWidth*.02}px 0 0 0;
            }
        }
        .iframe-container{
            border-top:3vw solid  #000000;
            @media(min-width:${containerMaxWidth}px){
                border-top:${containerMaxWidth*.03}px solid  #000000;
            }
            .podio-webform-frame{
                height:calc(${({iFrameHeight})=>iFrameHeight ? Number(iFrameHeight) : 1000}px + 3vw);
                width:100%;
                border:0px;
                outline:0px;
            }
        }
    }
`