import React from 'react';
import {StyledLanding} from './StyledComponents'
import MyHelmet from 'app/MyHelmet';

const Landing = (props) => {
  	return (
     		<StyledLanding>
				 <MyHelmet
					pageTitle='Welcome Home!'
					desc='501(c)3 public charity non-profit organization'
					imgPath='/imgs/openGraphLogo.png'
				/>
				 <div className="container">
					<div className="section-one">
						<img src="/imgs/triLogo.png" alt="Logo Spinning" className="spinning-logo"/>
						<img src="/imgs/faceLogoUpdated_faceSolo.png" alt="faceLogo" className="face-logo"/>
						<div className="tagline mobile">
							<div>
								<p>Advocate<br/>for<br/>Change</p>
								<p>Share<br/>your<br/>Cloud</p>
							</div>
						</div>
						<div className="tagline desktop">
							Advocate for Change.<br/>
							Share your Cloud.
						</div>
					</div>
					<div className="section-two">
						<div className="tagline">
							<h1>Mission:</h1>
							Nurture our society and environment<br/>
							by empowering our peers through<br/>
							education, inclusive action, music, and the arts.
						</div>
					</div>
					<div className="section-three">
						<div className="tagline">
							<h1>Story:</h1>

							<p>We began Share Your Cloud in 2014 as a result of conversations between friends and music enthusiasts about how
								to cultivate an atmosphere of activism within the community.</p>

							<p>Starting with an incredible turnout to our clothing and book drives we soon we began to
								incorperate more artist involvment. Friends donated their art for raffles over Papadosio's Thankgiving weekend in Cincinnati
								that year, encouraging donations in exchange for art giveaway tickets, it was a huge success and we've kept it rolling ever since!</p>

							<p>After two years of sharing clouds, in fall of 2016, we decided to change our name to Advocates of Change. We felt that
								this name more accurately and directly portrayed our mission and vision, while still holding on to our Papadosio roots.</p>

							<p>When we started this project over {Math.floor((new Date() - new Date(2014 , 11, 29))/1000/60/60/24/365)} years ago, we
							 had no idea it would develop as it has but we believed strongly in our community’s powerful desire to make a difference
							 together. Our project has become a platform for real social change, fueled entirely by the passion and creativity of the
							 music and arts community.</p>

							<p>We’ve helped heal and protect the environment, given hope to medical patients through tangible community support, given
								children the opportunity to develop through art and music, and have clothed and fed countless individuals. Thousands of
								hours given by hundreds of people have taught us that we can do anything with love and determination.</p>
						</div>
					</div>
				</div>
			 </StyledLanding>
    	)
}

export default Landing;