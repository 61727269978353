import React from 'react';
import {connect} from 'react-redux';
import StyledWallsToWindows from './StyledComponents';
import objShallowEqual from 'app/helperJS/objShallowEqual';
import MyHelmet from 'app/MyHelmet';
import Toggle from 'app/components/reusable/Toggle';


class WallsToWindows extends React.Component {
    state={
        iFrameHeight:0,
        iFrameWallRef:null,
        iFrameArtistRef:null,
        showWallForm:false,
    }

    componentDidMount(){
        this.setState({iFrameWallRef:React.createRef()})
        window._podioWebForm.receiveMessage((e)=>{
            if (this.state.iFrameWallRef && this.state.iFrameWallRef.current && typeof e.data === 'string') {
                const iFrameHeight = e.data.split(':')[1]
                this.setState({iFrameHeight},()=>console.warn('(Ignore Style Error) Height managed manually: ', this.state.iFrameHeight))
            }
        },1676550)

        this.setState({iFrameArtistRef:React.createRef()})
        window._podioWebForm.receiveMessage((e)=>{
            if (this.state.iFrameArtistRef && this.state.iFrameArtistRef.current && typeof e.data === 'string') {
                const iFrameHeight = e.data.split(':')[1]
                this.setState({iFrameHeight},()=>console.warn('(Ignore Style Error) Height managed manually: ', this.state.iFrameHeight))
            }
        },1676551)
    }
    shouldComponentUpdate(nextProps,nextState){
        return !objShallowEqual(this.state, nextState)
    }
    renderTotals(){
        let dreamOutLoudDonations = 0;
        let benificiariesDonations = 0;

        this.props.beneficiaries && this.props.beneficiaries.forEach(beneficiary=>{
            beneficiary.fields.forEach(field =>{
                if(field.external_id === 'total-donated'){
                    if (beneficiary.title === 'Dream Out Loud'){
                        dreamOutLoudDonations += Number(field.values[0].value);
                    }else{
                        benificiariesDonations += Number(field.values[0].value);
                    }
                }
            })
        })
        return({dreamOutLoudDonations,benificiariesDonations})
    }

    renderArtistForm(){
        return <iframe
            ref={this.state.iFrameArtistRef}
            title="artist-form"
            className="podio-webform-frame"
            id="podioWebForm1676551662135"
            src="https://podio.com/webforms/23401387/1676551?e=true#http%3A%2F%theadvocatesofchange.com%2Fwalls-to-windows"
        ></iframe>
    }

    renderWallsForm(){
        return <iframe
            ref={this.state.iFrameWallRef}
            title="walls-form"
            className="podio-webform-frame"
            id="podioWebForm167655077128"
            src="https://podio.com/webforms/23401386/1676550?e=true#http%3A%2F%2Ftheadvocatesofchange.com%2Fwalls-to-windows"
        ></iframe>
    }

    render(){
        return(

            <StyledWallsToWindows iFrameHeight={this.state.iFrameHeight}>
                <MyHelmet
					pageTitle='Walls To Windows Program'
					desc='Advocates of Change program that turns regular walls into windows to the imagination and to hope and joy.'
					imgPath='/imgs/openGraphLogo.png'
				/>
                <div className="container">
                    <div className="title">Walls To Windows</div>
                    <p>
                        Our organization has seen how powerful art has the potential to inspire and encourage communities. Through Walls To Windows we’ll be harnessing that potential by working with artists across the country to donate full-size murals to community centers, after school programs, and jails wherever possible! Turning regular walls into windows to the imagination and to hope and joy.
                    </p>
                    <hr/>
                        <img src="https://www.dropbox.com/s/sorr7hxkos7nnl2/mural1.jpg?raw=1" alt="Powhatan Wall" />
                    <h1>"Good Trouble"</h1>
                    <h3>by Josh Zarambo and Lacey Vilandry</h3>
                    <h3>Powhatan Community Center - Richmond, VA</h3>
                    <p>
                        Advocates of Change is pleased to present this mural honoring the late Rep John Lewis, "Good Trouble", painted by <a href="www.zaramboart.com" rel="noopener noreferrer" target="_blank" ><u>Josh Zarambo</u></a> (<a href="www.zaramboart.com" rel="noopener noreferrer" target="_blank" >@<u>zaramboart IG</u></a>) and donated to the Powhatan Hill Community Center in Richmond, VA!
                    </p>
                    <p>
                        Good Trouble is the first installment of our "Walls To Windows" program. The title of the mural comes from a stirring and uplifting call to action, a direct quote from Rep Lewis, May all who see the mural or hear his words have that hope and optimism to sustain our courage in the struggle for equality and justice and peace.
                    </p>
                    <div className="quote">
                        "Do not get lost in a sea of despair. Be <u>hopeful</u>, be <u>optimistic</u>. Our struggle is not the struggle of a day, a week, a month, or a year, it is the struggle of a lifetime. Never, ever be afraid to make some noise and get in good trouble,<br />necessary trouble."
                        <br /> - Rep John Lewis
                    </div>
                    <p>
                        Huge thanks to Lockn and HeadCount for their sponsorship and support through Participation Row last year, and to all our other friends and supporters who donated online and at Papadosio shows - we couldn't have done this without you!
                    </p>
                    <p>
                        We originally planned to paint another design, one voted on by Lockn' fans, but were compelled to pivot at the last minute. We figured it was very fitting to use the space to honor the civil rights leader and voting rights champion John Lewis.
                    </p>
                    {/* <img src="https://www.dropbox.com/s/sxugewv2mx0f3de/mural1collage.jpg?raw=1" alt="Powhatan Wall Collage" /> */}
                    <div className="iframe-container">
                        <Toggle labelSize={3.2} falsy="Artist" truthy="Wall" onSubmit={showWallForm => this.setState({showWallForm})}/>
                        {this.state.showWallForm ? this.renderWallsForm() : this.renderArtistForm()}
                    </div>
                </div>
            </StyledWallsToWindows>
        )
    }
}

export default connect(state=>state)(WallsToWindows);