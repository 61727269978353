import styled from 'styled-components';

export const StyledFooter = styled.div`

	.footer-menu {
		z-index: 1050;
		top: 0;
		width: 100%;
		display:grid;
		grid-template-columns:auto;
		grid-template-rows: auto auto auto;
		grid-template-areas:
							'links'
							'nonProfit'
							'webcreator';
		justify-content:center;
		align-content:center;
		padding:1rem;
		background-color: black;
		color: white;
		font-size:2vw;
		text-transform:uppercase;


		@media(min-width:800px){
			grid-template-rows:auto;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-areas:'nonProfit links webcreator';
			font-size: 1vw;
		}

		.footer-links-list{
			grid-area:links;
			display:flex;
			flex-direction:column;
			justify-content:center;
			align-content:center;
			margin:1vw 0;

			.links{
				display:flex;
				justify-content:center;
				align-content:center;
				.footer-links-item{
					margin: 0 0;
					display:flex;
					align-items:center;


					.footer-link{
						padding:0 16px;
						/* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); */
					}
					img{
						height: 10vw;
						max-height:44px;
					}
				}
			}
			.donation{
				display:flex;
				justify-content:center;
				align-content:center;
				input{
						height:20vw;
						max-height:88px;
					}
			}
		}
		.footer-np {
			grid-area:nonProfit;
			align-self:center;
			justify-self:center;
			margin: .5vw 0 1vw 0;
			text-align:center;
		}
		.footer-webcreator {
			grid-area:webcreator;
			margin:16px 0px;
			text-align:center;
			display:flex;
			flex-direction:column;
			justify-content:center;
			a{
				color:inherit;
				text-decoration:none;
			}
		}
	}
`;