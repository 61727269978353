import React from 'react';
import Styled from './Styled';


export default class Toggle extends React.Component {
    state={
        toggle:false
    }
    componentDidMount(){
        this.props.startingValue && this.setState(
            {toggle:this.props.startingValue},
            ()=>this.onSubmit()
            )
    }

    onSubmit=()=>{
        this.props.onSubmit(this.state.toggle)
    }


    render(){
        return (
            <Styled labelSize={this.props.labelSize}>
                <div className="toggle-switch">
                    <div className="label">{this.props.falsy}</div>
                    <label className="switch">
                        <input 
                            type="checkbox"
                            onChange={e=>{
                                this.setState(
                                    {toggle:e.target.checked},
                                    ()=>this.onSubmit(this.state.toggle)
                                )
                            }}
                            value={this.state.toggle}
                        />
                        <span className="slider"></span>
                    </label>
                    <div className="label">{this.props.truthy}</div>
                </div>
            </Styled>
        )
    }
}