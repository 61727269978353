import styled from 'styled-components';

const cardMaxWidth = 390;
const containerMaxWidth = 1300;
const breakA = 500;
const break1= 720;
const break2= 1100;

export default styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 100vw;
    .title{
        grid-area:title;
        font-size:7vw;
        font-family: 'Special Elite', Sans-Serif;
        margin:2vw 0 0 0;
        @media(min-width:${containerMaxWidth}px){
            font-size:${(containerMaxWidth*.07)/16}rem;
            margin:${containerMaxWidth*.02}px 0 0 0;
        }
    }
    h2{
        font-family: 'Special Elite', Sans-Serif;
        font-size:1.5em;
        @media(min-width:${breakA}px){
            font-size:2em;
        }
    }
    h3{
        margin:0 0;
    }
    p{
        margin:0 0;
    }
    .card{
        max-width:${cardMaxWidth}px;
        letter-spacing:1px;
        line-height:1.25rem;
    }
    .container{
        max-width:${containerMaxWidth}px;
        padding:16px;
        /* line-height:1.25rem; */
        text-align:center;
        display:flex;
        flex-direction:column;
        align-items:center;

        @media(min-width:${break1}px){
            display:grid;
            align-items:start;
            justify-items:center;
            grid-template-columns:1fr 1fr;
            grid-template-areas:'title title '
                                'tagline tagline '
                                'goal why'
                                'who what'
                                'how where'
                                'when when'
                                'awardees awardees'
                                'form form';
        }
        @media(min-width:${break2}px){
            display:grid;
            align-items:start;
            justify-items:center;
            grid-template-columns:1fr 1fr 1fr;
            grid-template-areas:'title title title'
                                'tagline tagline tagline'
                                'goal goal goal'
                                'why who what'
                                'when how where'
                                'awardees awardees awardees'
                                'form form form';
            .goal{
                max-width:${containerMaxWidth}px;
                font-size:2rem;
                line-height:2.25rem;
                margin-bottom:16px;
            }
        }
        .title{
            grid-area:title;
            font-size:7vw;
            font-family: 'Special Elite', Sans-Serif;
            margin:2vw 0 0 0;
            @media(min-width:${containerMaxWidth}px){
                font-size:${(containerMaxWidth*.07)/16}rem;
                margin:${containerMaxWidth*.02}px 0 0 0;
            }
        }
        .tag-line{
            grid-area:tagline;
            font-size:2.5vw;
            margin-top:8px;
            font-family: 'Special Elite', Sans-Serif;
            @media(min-width:${containerMaxWidth}px){
                font-size:${(containerMaxWidth*.025)/16}rem;
            }
        }
        .awardees{
            grid-area:awardees;
        }
        .goal{
            grid-area:goal;

        }
        .why{
            grid-area:why;

        }
        .who{
            grid-area:who;

        }
        .what{
            grid-area:what;

        }
        .when{
            grid-area:when;

        }
        .how{
            grid-area:how;

        }
        .where{
            grid-area:where;

        }
        .podio-webform-frame{
            grid-area:form;
            height:calc(${({iFrameHeight})=>iFrameHeight ? Number(iFrameHeight) : 1000}px + 3vw);
            width:100%;
            border:0px;
            border-top:3vw solid  #000000;
            margin-top:2rem;
            outline:0px;
            @media(min-width:${containerMaxWidth}px){
                border-top:${containerMaxWidth*.03}px solid  #000000;
            }
        }
    }
`