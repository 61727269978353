import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PaypalButton from './PayPalButton';
import * as actions from 'app/redux/actions'

const ENV = process.env.NODE_ENV === 'production' && process.env.REACT_APP_NODE_ENV !== 'staging' ? 'production' : 'sandbox';
const CLIENT = {
    sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
    production: process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION,
}

const PayPalCheckoutButton = ({cart,cartTotal,shippingCost,cartCustomer,setCart,setCartStatus,getInventory,addTracking,setModal}) => {
    const onSuccess = async (response) => {
        const {paymentID} = response
        setModal(false)
        setCartStatus('posting')
        try{
            var res = await axios.post('/api/order', {cart , customer: cartCustomer , paymentID })
        }catch (err){
            setCartStatus({err: err.response && err.response.data && err.response.data.err ? err.response.data.err.join(', ') : JSON.stringify(err.response.data)})
            console.error(err.response.data.err)
            return err.response.data.cart && setCart(err.response.data.cart)
        }
        if(res.data.message === 'Order Complete'){
            setCartStatus('complete')
            setCart({})
            setTimeout(async ()=>{
                setCartStatus('getting inventory')
                try{
                    await getInventory();
                }catch (err){
                    setCartStatus({err})
                    return console.error(err)
                }

                setCartStatus('ready')
            },1000)
        }
    }
    const onError = (err) => {
        setCartStatus({err})
        return console.error('Erroneous payment OR failed to load script!', err)
    }
    const onCancel = (data) => {
        setCartStatus({err: 'Cancelled PayPal Screen'})
        console.warn('Cancelled payment!', data);
    }
    return (
        <PaypalButton
            client={CLIENT}
            env={ENV}
            commit={true}
            currency={'USD'}
            cartTotal={Number(cartTotal)}
            shippingCost={Number(shippingCost)}
            onSuccess={onSuccess}
            onError={onError}
            onCancel={onCancel}
        />
    );
}
export default connect(
    ({cart,cartStatus,cartCustomer,shipping})=>({cart,cartStatus,cartCustomer,shipping}),
    actions
)(PayPalCheckoutButton);