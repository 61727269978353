export const GET_EVENTS = 'get_events';
export const SET_MAP_BOUNDS = 'set_map_bounds';
export const GET_BENEFICIARIES = 'get_beneficiaries';
export const GET_VOLUNTEERS = 'get_volunteers';
export const GET_TEAM = 'get_team';
export const GET_AWARDEES = 'get_awardees';
export const SET_TOTAL_DONATION = 'set_total_donation';

export const GET_SHIPPING = 'get_shipping';
export const ADD_TRACKING = 'add_tracking';
export const CLEAR_SHIPPING = 'clear_shipping';

export const GET_RAFFLES = 'get_raffles';
export const GET_INVENTORY = 'get_inventory';
export const GET_ROTATING_BENEFICIARY = 'get_rotating_beneficiary';

export const UPDATE_STATUS_CODE_FREE_RAFFLE = 'UPDATE_STATUS_CODE_FREE_RAFFLE';

export const SET_CART = 'set_cart';
export const UPDATE_CART = 'update_cart';
export const SET_CART_STATUS = 'set_cart_status';
