import React , {useEffect} from 'react';
import { connect } from 'react-redux';
import {FilterSection} from './StyledComponents';

const Store = ({inventory , setSortBy , setArtistFilter , setTypeFilter , setArtists , setTypes , sortBy , artistFilter , typeFilter , artists , types}) => {

    useEffect(()=>{
        if(inventory.notLoaded) return
        const setStateObjet = inventory.reduce((acc,item)=>{
            return {
                artists:{...acc.artists , [item.artistName]:1},
                types:{...acc.types , [item.category]:1}
            }
        },{artists:{},types:{}})
        setArtists(Object.keys(setStateObjet.artists))
        setTypes(Object.keys(setStateObjet.types))
    },[inventory,setArtists,setTypes])

    if(inventory.notLoaded) return null
    return(
            <FilterSection>
                <div>
                    <label>Sort</label>
                    <select className='sort-by' onChange={({target:{value}})=>setSortBy(value)} value={sortBy}>
                        <option value='title'>Title</option>
                        <option value='artistName'>Artist</option>
                        <option value='cost'>Price</option>
                    </select>
                </div>
                <div>
                    <label>Artist</label>
                    <select className='filter-artist' onChange={({target:{value}})=>setArtistFilter(value)} value={artistFilter}>
                        <option value='none'>Select Artist</option>
                        {artists.map((artist,index)=><option key={artist + index} value={artist}>{artist}</option>)}
                    </select>
                </div>
                <div>
                    <label>Type</label>
                    <select className='filter-type' onChange={({target:{value}})=>setTypeFilter(value)} value={typeFilter}>
                        <option value='none'>Select Type</option>
                        {types.map((type,index)=><option key={type +index} typevalue={type}>{type}</option>)}
                    </select>
                </div>
            </FilterSection>
    )
}

export default connect(({inventory,rotatingBeneficiary})=>({inventory,rotatingBeneficiary}))(Store)