import styled from 'styled-components';
const maxWidth = 1300;
export default styled.form`
    display:flex;
    flex-direction:column;
    align-items:center;
    select{
        width: auto;
        height: auto;
        outline:none;
        font-size:1.6vw;
        @media(min-width:1000px){
            font-size: 1rem;
        }
    }
    .toggle-switch{
        display:flex;
        justify-content:center;
        align-items:center;
        margin:8px 8px;
        width:60vw;
        @media(min-width:${maxWidth}px){
            width:${maxWidth * .6}px
        }
        .label{
            /* vertical-align:middle; */
            margin:8px 8px;
            flex:1;
            font-size:${ ({labelSize}) => labelSize || 1 }vw;
            text-align:center;
            @media(min-width:${maxWidth}px){
                font-size: ${ ({labelSize}) =>{
                    const size = labelSize || 1 
                    return (maxWidth * size * .01)/16
                }}rem
               
            }
        }
        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 34px;

                &:before {
                    position: absolute;
                    content: "";
                    height: 26px;
                    width: 26px;
                    left: 4px;
                    bottom: 4px;
                    background-color: white;
                    -webkit-transition: .4s;
                    transition: .4s;
                    border-radius: 50%;
                }
            }
            input {
                opacity: 0;
                width: 0;
                height: 0;
                &:checked + .slider {
                    background-color: #2196F3;
                }
                &:focus + .slider {
                    box-shadow: 0 0 1px #2196F3;
                }
                &:checked + .slider:before {
                    -webkit-transform: translateX(26px);
                    -ms-transform: translateX(26px);
                    transform: translateX(26px);
                }
            }

        }
    }

`