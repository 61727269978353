import styled from 'styled-components';

const minWidth = '1100';
const navSwitch = '1900';

export const StyledTopNav = styled.div`
	.nav-menu {
		z-index: 1050;
		top: 0;
		width: 100%;
		display: flex;
		border-bottom: 3vw solid  #000000;
		justify-content:space-between;
		background-color: white;
		padding:1rem 2rem;
		@media(min-width:${minWidth}px){
			border-bottom: ${minWidth*.03}px solid  #000000;
		}
		.nav-links-button{
			display: flex;
			flex-direction:column;
			background-color:white;
			align-items:center;
			justify-content:center;
			outline:none;
			@media(min-width:${navSwitch}px){
				display:none;
			}
		}

		.nav-links-list{
			display:none;
			justify-content:space-between;
			width:75%;
			padding-right:2vw;
			align-self:center;
			@media(min-width:${navSwitch}px){
				display:flex;
			}

			.nav-links-item{
				margin: 0 0;
				padding:0 8px;
				color:#000000;

				.nav-link{
					color:inherit;
					text-decoration: none;
					height:100%;
					width:100%;
					font-size:3.25vw;
					text-transform: uppercase;

					@media(min-width:600px){
						font-size:1.25rem;
					}
				}
			}
		}
		.nav-brand {
			align-self:center;
			outline:none;
			.nav-logo {
				img{
					height:8vw;
					min-height:50px;
					vertical-align:middle;


					@media(min-width:${minWidth}px){
						height:${minWidth*.08}px;
					}
				}
			}
		}
	}
`;