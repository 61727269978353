import React from "react";
import { GoogleMap,  MarkerClusterer, Marker } from '@react-google-maps/api'

export default class Map extends React.Component {
    state={map:null}

    constructor(props){
        super(props)
        this.mapContainerStyle = props.mapContainerStyle || {height: "400px",width: "80%"}
        this.zoom = props.zoom || 3;
        this.center = props.center || {lat: 39.0420, lng: -97.420};
        this.markerClusterOptions = {imagePath: props.imagePath || "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"}
        this.markerIcon = props.icon || undefined;
        this.mapStyles = props.mapStyles || [
            {
                'featureType': 'all',
                'elementType': 'labels',
                'stylers': [{'visibility': 'off'}]
            },
            {
                'featureType': 'administrative',
                'elementType': 'labels',
                'stylers': [{'visibility': 'on'}]
            },
            {
                'featureType': 'landscape',
                'elementType': 'labels',
                'stylers': [{'visibility': 'on'}]
            },
            {
                'featureType': 'road',
                'elementType': 'labels',
                'stylers': [{'visibility': 'on'}]
            },
            {
                'featureType': 'transit',
                'elementType': 'labels',
                'stylers': [{'visibility': 'on'}]
            },
            {
                'featureType': 'water',
                'elementType': 'labels',
                'stylers': [{'visibility': 'on'}]
            }
        ];
    }

    onLoadMap = map => this.setState({map},()=>this.state.map.setOptions({styles:this.mapStyles}));

    onBoundsChanged = () => {
        const bounds = this.state.map.getBounds();
        this.props.onBoundsChanged({
            neLat: bounds.getNorthEast().lat(),
            neLng: bounds.getNorthEast().lng(),
            swLat: bounds.getSouthWest().lat(),
            swLng: bounds.getSouthWest().lng()
        });
    }

    logoClick(center){
        this.state.map.panTo(center)
        this.state.map.setZoom(this.state.map.zoom + 1)
    }

    render(){
        return (
            <GoogleMap
                onLoad={this.onLoadMap}
                mapContainerStyle={this.mapContainerStyle}
                zoom={this.zoom}
                center={this.center}
                onIdle={this.onBoundsChanged}
            >
                <MarkerClusterer options={this.markerClusterOptions} >
                    {(clusterer) => this.props.coords.map((location, i) =>(
                        <Marker
                            key={i}
                            position={location.coords}
                            clusterer={clusterer}
                            icon={this.markerIcon}
                            onClick={()=>this.logoClick(location.coords)}
                        />
                    ))}
                </MarkerClusterer>
            </GoogleMap>
        )
    }
}