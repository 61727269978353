import React from 'react';
import StyledContact from './StyledComponents';
import objShallowEqual from 'app/helperJS/objShallowEqual';
import MyHelmet from 'app/MyHelmet';

class Contact extends React.Component {
    state={
        iFrameHeight:0,
        iFrameRef:null
    }

    componentDidMount(){
        this.setState({iFrameRef:React.createRef()})
        window._podioWebForm.receiveMessage((e)=>{
            if (this.state.iFrameRef && this.state.iFrameRef.current && typeof e.data === 'string') {
                const iFrameHeight = e.data.split(':')[1]
                this.setState({iFrameHeight},()=>console.warn('(Ignore Style Error) Height managed manually: ', this.state.iFrameHeight))
            }
        },1340506)
    }
    shouldComponentUpdate(nextProps,nextState){
        return !objShallowEqual(this.state, nextState)
    }
    render(){
        return(
            <StyledContact iFrameHeight={this.state.iFrameHeight}>
                 <MyHelmet
                    pageTitle='Contact'
                    desc='Contact Form'
                    imgPath='/imgs/openGraphLogo.png'
                />
                <div className="container">
                    <iframe
                        ref={this.state.iFrameRef}
                        title="contact-webform"
                        className="podio-webform-frame"
                        id="podioWebForm1340506751787"
                        src="https://podio.com/webforms/19789538/1340506?e=true#http%3A%2F%2Ftheadvocatesofchange.com%2Fcontact-us%2F"
                    ></iframe>
                </div>
            </StyledContact>
        )
    }
}

export default Contact