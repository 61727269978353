import React from 'react';
import { connect } from 'react-redux';
import StyledTeam from './StyledComponents';
import objShallowEqual from 'app/helperJS/objShallowEqual'
import MyHelmet from 'app/MyHelmet';

class Team extends React.Component {
    state={
        show:[],
        bufferRefs:[],
        containerRef:null,
        offSet:[],
    }
    componentDidMount(){
        const bufferRefs = []
        for(let i = 0; i<20; i++){
            bufferRefs.push(React.createRef())
        }
        this.setState({bufferRefs})
        this.setState({containerRef:React.createRef()})
    }

    onClick(index){
        const {bufferRefs, containerRef , show, offSet} = this.state

        const newShow = []
        if(!show[index]) newShow[index] = true

        const bufferOffest = bufferRefs[index].current.getBoundingClientRect().left
        const appWidth = containerRef.current.getBoundingClientRect().width

        const newOffSet = (Number(bufferOffest)/Number(appWidth))*-100;
        offSet[index] = newOffSet
        this.setState({show:newShow, offSet})
    }

    volunteerLi(volunteer){
        return (
            <li key={volunteer.item_id}>
                <span
                    className={`name ${volunteer.fontSize} ${volunteer.justify} ${volunteer.fontFamily} category-4`}
                >
                    {volunteer.title}
                </span>
            </li>
        )
    }

    teamLi(team,index){
        let fullName, title, bio, img, cityState, category;
        team.fields.forEach(field=>{
            switch(field.external_id){
                case 'title':
                    fullName = field.values[0].value
                    break;
                case 'title-2':
                    title = field.values[0].value
                    break;
                case 'bio':
                    bio = field.values[0].value
                    break;
                case 'photo-url':
                    img = field.values[0].embed.resolved_url.replace('?dl=0','?raw=1')
                    break;
                case 'city-state':
                    cityState = field.values[0].value
                    break;
                case 'category':
                    category = field.values[0].value.id
                    break;
                default:
                    break;
            }
        })
        return(
            <li key={team.item_id} className={this.state.show[index] ? 'show' :'hide'}>
                <span
                    className={`name team  pointer category-${category}`}
                    onClick={()=> this.onClick(index)}
                >
                    {team.title}
                </span>
                <div className="info-buffer" ref={this.state.bufferRefs[index]}>
                    <div className={`info-box`} style={{left:`${this.state.offSet[index]}vw`}}>
                        <div className= 'info-container' >
                            <div className='img'>
                                <img src={img} alt='Team Member'/>
                            </div>
                            <div className='content'>
                                <div className='fullName'>{fullName}</div>
                                <div className='title'>{title}</div>
                                <div className='city-state'>{cityState}</div>
                                <div dangerouslySetInnerHTML={{ __html: bio }} />
                            </div>
                        </div>
                        <div className='shadow'></div>
                    </div>
                </div>
            </li>
        )
    }

    renderList(){
        const {volunteers , team} = this.props.crew;
        const volLength = volunteers.length;
        const teamLength = team.length

        const freq  = Math.floor(volLength / teamLength) || 1;
        const list = [];
        let teamIndex = 0;
        const maxI = volLength > teamLength ? volLength : teamLength;

        for(let i = 0; i < maxI; i++ ){
            if(i%freq === 0 && teamIndex < teamLength){
                list.push(this.teamLi(team[teamIndex],teamIndex))
                teamIndex++
            }
            if(i<volLength){
                list.push(this.volunteerLi(volunteers[i]))
            }
        }
        return(
            <ul>
                {list}
            </ul>
        )
    }

    shouldComponentUpdate(nextProps, nextState){
        if (objShallowEqual(this.props.crew.team , nextProps.crew.team) && objShallowEqual(this.props.crew.volunteers , nextProps.crew.volunteers) && objShallowEqual(this.state , nextState))
            return false
        return true
    }

    render(){
        if(this.props.crew.team.notLoaded || this.props.crew.volunteers.notLoaded) return <div/>
        return(
            <StyledTeam
                offSet={this.state.offSet}
            >
                <MyHelmet
					pageTitle='Our Team'
					desc='Our Mission Statement Signed by our Directors, Team Leaders, Volunteers, and Support'
					imgPath='/imgs/openGraphLogo.png'
				/>
                <div ref={this.state.containerRef} className="container">
                    <h1>Our Team</h1>
                    <div className='tagline'>
                        <div className="title">Mission Statement:</div>
                        <div className="mission">
                        Nurture our society and environment<br/>
                        by empowering our peers through<br/>
                        education, inclusive action, music, and the arts.
                        </div>
                    </div>
                    <div className="signed-by">
                        Signed by our amazing:<br/>
                        <span className="category-1">Directors</span>, <span className="category-2">Team Leaders</span>, <span className="category-4">Volunteers</span>, & <span className="category-3">Support</span>
                    </div>
                    {this.renderList()}
                </div>
            </StyledTeam>
        )
    }
}

export default connect(state=>state)(Team)