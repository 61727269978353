import React from 'react';
import { connect } from 'react-redux';
import { SectionTitle , LineItem} from './StyledComponents';

const OrderList = ({cart}) => {
    return <>
      <SectionTitle>Your Order</SectionTitle>
      <ul>
        {Object.keys(cart).map((item_id)=>{
            const item = cart[item_id]
            if(!Object.keys(item.qtys).length) return null

            return (
              <LineItem key={item_id}>
                <img src={item.imageLink[0]} alt={item.title}></img>
                <div className='details'>
                  <div className='title' >{item.title}</div>
                  {Object.keys(item.qtys).map(size=>(
                    <div className='qty' key={item_id + size}>
                      ({size}) x {item.qtys[size]} = ${item.cost * item.qtys[size]}
                    </div>
                  ))}
                </div>
              </LineItem>
        )})}
      </ul>
    </>
  }

export default connect(
  ({cart})=>({cart})
)(OrderList)