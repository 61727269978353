import axios from 'axios';

import { UPDATE_STATUS_CODE_FREE_RAFFLE } from '../actions/types';

export const makeFreeRaffle = (raffle) => async (dispatch) => {
  dispatch({ type: UPDATE_STATUS_CODE_FREE_RAFFLE, payload: 'pending' });
  return axios
    .post('/api/free-raffles/', raffle)
    .then(() =>
      dispatch({ type: UPDATE_STATUS_CODE_FREE_RAFFLE, payload: 'success' })
    )
    .catch((e) =>
      e.message === 'Request failed with status code 409'
        ? dispatch({
            type: UPDATE_STATUS_CODE_FREE_RAFFLE,
            payload: `Only 1 email allowed ${
              raffle.eventCode.length ? 'per event' : ''
            }`,
          })
        : dispatch({ type: UPDATE_STATUS_CODE_FREE_RAFFLE, payload: e.message })
    );
};
