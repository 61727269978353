import styled from 'styled-components';

export default styled.div`
    margin:1rem;
    position:relative;
    .hr{
        position:relative;
        top:0; left:0; right:0;
        margin-left:auto;
        margin-right:auto;
        width:50%;
        border: 5px solid black;
        background:black;
        border-radius:5px;
    }
    .title{
        font-family:'Avenir Next Bold',sans-serif;
        margin-bottom:0;
        font-size:1.2rem;
    }
    form{
        .form-inputs{
            display:flex;
            flex-direction:column;
            align-items:center;
            justify-items:center;
            margin:8px;

            .label{
                font-size:1rem;
                width:100%;
            }
            .email{
                width:100%;
                padding:.25rem;
                border-radius:5px;
                font-size:1rem;
            }
            .submit{
                width:100%;
                background-color:black;
                color:white;
                border-radius:5px;
                font-size:1rem;
            }
            @media(min-width:500px){
                flex-direction:row;
                .label{
                    width:auto;
                }
                .email{
                    flex:1;
                    width:auto;
                    margin:0 .5rem;
                    outline:none;
                }
                .submit{
                    width:auto;
                    outline:none;
                }
            }
        }
        .error{
            font-family:'Bobby Jones',Sans-Serif;;
            color:#D8000C;
        }
        .success{
            font-family:'Bobby Jones',Sans-Serif;;
            color:#4BCA81;
        }
        .processing{
            font-family:'Bobby Jones',Sans-Serif;;
            color:#FAD201;
        }
    }
`