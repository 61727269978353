import styled from 'styled-components';
// import {media , screenSizes} from 'app/components/mediaObject';

export const CheckOutModalButton = styled.button`
    height:4.50em;
    width:4.50em;
    padding:0;
    margin:auto;
    border-radius: 50%;
    position:fixed;
    bottom:2em;
    right:0;left:0;margin:0 auto;
    background-color: #7ACDF5;
    color:white;
    font-family: 'Baloo Bhai', Sans-Serif;
    text-align:center;
    /* text-shadow: rgba(100,100,100,.75) .25em 0 .5em; */
    box-shadow: 0 0 .5em .5em rgba(0,0,0,.2);
    border: .25em solid white;
    font-size:2em;
    z-index:1000;
    cursor: pointer;
`
export const CheckOutButton = styled.button`
    /* height:4.50em; */
    width:100%;
    padding:0;
    margin:auto;
    /* border-radius: 50%; */
    /* position:fixed; */
    bottom:2em;
    right:0;left:0;margin:0 auto;
    background-color: #7ACDF5;
    color:white;
    font-family: 'Baloo Bhai', Sans-Serif;
    text-align:center;
    /* text-shadow: rgba(100,100,100,.75) .25em 0 .5em; */
    /* box-shadow: 0 0 .5em .5em rgba(0,0,0,.2); */
    border: .25em solid white;
    font-size:2em;
    z-index:1000;
    cursor: pointer;
`