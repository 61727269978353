const formatMoney = (n, C, D, T)=>{
  var c = isNaN(C = Math.abs(C)) ? 2 : C,
      d = D === undefined ? "." : D,
      t = T === undefined ? "," : T,
      s = n < 0 ? "-" : "",
      i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
      j = i.length > 3 ? i.length % 3 : 0;
     return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

export default formatMoney