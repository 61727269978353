import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {connect} from 'react-redux';
import * as actions from 'app/redux/actions'
import {StyledCartSection , CartButton, SizeSection} from './StyledComponents';
import formatMoney from 'app/helperJS/formatMoney'
import { RotatingBeneficiaryInfoIcon } from 'app/components/routes/Store/StyledComponents';

const SelectASizeCartButtons = ({item , qtys , hover , updateCart, position, storeType, unitLabel}) => {
    const renderQTY = (size) => {
        const quantity = (qtys[size]||0)
        return <SizeSection key={item._id + size}>
            <CartButton color='#ff726f' onClick={()=>updateCart({item,size,storeType,qty:-1})}>
                <i className="material-icons">clear</i>
            </CartButton>
            <span className="quantity">
                {quantity} for ${quantity * item.cost}
            </span>

            <CartButton color='#98FB98' onClick={()=>updateCart({item,size,storeType,qty:1})}>
                <i className="material-icons">add</i>
            </CartButton>
        </SizeSection>
    }
    return(
        <StyledCartSection position={position} qtys={qtys} hover={hover}>
            {renderQTY('oneSize')}
            <div className='cost-section'>
                {item.rotatingBeneficiaryElegible && <HashLink smooth to='/store#RotatingBeneficiariesSection' >
                    <RotatingBeneficiaryInfoIcon style={{
                        margin: '0 1em',
                        visibility: item.rotatingBeneficiaryElegible ? 'vizible' : 'hidden'
                    }}>RB</RotatingBeneficiaryInfoIcon>
                </HashLink>}
                <span className="cost">${formatMoney(item.cost)}{unitLabel || ''}</span>
            </div>
        </StyledCartSection>
    )
}

export default connect(null,actions)(SelectASizeCartButtons)