import styled from 'styled-components';

const firstBreak = 420;
const maxWidth = 600;

export default styled.div`
    .container{
        display:flex;
        flex-direction:column;
        align-items:center;
        margin:16px 0;
        h1{
            text-align:center;
            font-size:7vw;
            margin:2vw 0 .25rem 0;
            font-family: 'Special Elite', Sans-Serif;

        }
        h3{
            text-align:center;
            margin: 0 0;
            padding: 0 0 .5rem 0;
            @media(min-width:${firstBreak}px){
                font-size:1.3rem;
            }
            @media(min-width:${maxWidth}px){
                font-size:1.5rem;
            }
        }
        table{
            width:80vw;
            max-width:${maxWidth * .8}px;

            tbody{
                font-size:3vw;
                @media(min-width:${maxWidth}px){
                    font-size:${(maxWidth*.03)/16}rem;
                }
                tr{
                    td{
                        padding:.25rem 0;
                        a{
                            color:inherit;
                        }
                    }
                }

            }
        }
    }
`