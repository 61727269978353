import styled from 'styled-components';
import { media } from 'app/components/mediaObject'

export const StyledItemCard = styled.div`
    width:100vw;
    min-height:100vw;
    margin: 2vw 0;
    text-align:center;
    position:relative;
    .link{
        width:100%;
        height:100%;
        display:flex;
        justify-content:center;
        align-items:center;
        img{
            width:100%;
            max-height:100%;
        }
    }

    .title{
        font-family: 'Baloo Bhai', Sans-Serif;
        font-size:5vw;
        position:absolute;
        width:100%;
        background-color:white;
        /* border-top:2px solid black; */
        /* border-bottom:2px dotted black; */
    }


    ${media.tablet`
        width:25vw;
        height: 25vw;
        min-height:0;
        margin: 2vw 2vw;
        border:1vw solid black;
        :hover{
            .title{
                visibility:visible;
            }
        }
        .title{
            visibility:hidden;
            font-size:2vw
            border-top:none;
        }
    `}

    ${media.desktop`
        width:11rem;
        height: 11rem;
        margin: .5rem .5rem;
        border:.5vw solid black;
        .title{
            font-size:.75rem;
        }
    `}
`