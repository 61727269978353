import styled from 'styled-components';


export default styled.div`
    .container{
        display:flex;
        flex-direction:column;
        align-items:center;
        margin:16px 0;
        h1{
            text-align:center;
            margin: 0 0;
            font-size:7vw;
            margin:2vw 0 .25rem 0;
            font-family: 'Special Elite', Sans-Serif;
        }
        .list-container {
            width:100%;
            h1{
                font-size:2.25em;
                padding:1rem;
            }
        }
    }
`