import React from 'react';
import {Container, Name, ImgContainer, StyledImg, StyledContent} from './StyledComponents';
import formatMoney from 'app/helperJS/formatMoney';

const Awardee = ({awardee, className}) => {
    let fullName, title, bio, img, cityState, amountAwarded, dateAwarded, website;
    awardee.fields.forEach(field=>{
        switch(field.external_id){
            case 'title':
                fullName = field.values[0].value
                break;
            case 'title-2':
                title = field.values[0].value
                break;
            case 'amount-awarded':
                amountAwarded = '$' + formatMoney(field.values[0].value)
                break;
            case 'website-or-social-media-url':
                website = field.values[0].embed.resolved_url
                break;
            case 'bio':
                bio = field.values[0].value
                break;
            case 'photo-url':
                img = field.values[0].embed.resolved_url.replace('?dl=0','?raw=1')
                break;
            case 'city-state':
                cityState = field.values[0].value
                break;
            case 'date-awarded':
                dateAwarded = field.values[0].start.replace(' 00:00:00', '')
                break;
            default:
                break;
        }
    })
    const [year, month, day] = dateAwarded.split('-')
    return(
        <Container key={awardee.item_id} className={className}>
            <Name>
                {awardee.title}
            </Name>
            <ImgContainer>
                <StyledImg src={img} alt='Awardee'/>
            </ImgContainer>
            <StyledContent>
                {website
                    ? <a href={website} rel="noopener noreferrer" target="_blank"><u>{fullName}</u></a>
                    : <div >{fullName}</div>
                }
                <div >{title}</div>
                <div >{cityState}</div>
                <div >{month}-{day}-{year}</div>
                <div style={{marginBottom:'1em'}} >{amountAwarded}</div>
                <div dangerouslySetInnerHTML={{ __html: bio }} />
            </StyledContent>
        </Container>
    )
}

export default Awardee