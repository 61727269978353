import styled from 'styled-components';
import { media } from 'app/components/mediaObject'

export const StyledMoreInfoModal = styled.div`
    text-align:center;
    display:flex;
    flex-direction:column;

    .display-image{
        width:100%;
    }
    .sub-image{
        height:2rem;
        margin:.5rem .5rem 0 0;
        border:1px solid black;
    }
    .more-images{
        display:flex;
        flex-wrap:wrap;
    }
    .title{
        padding-top:2.5vw;
        font-family: 'Baloo Bhai', Sans-Serif;
        font-size:6vw;
    }
    .header{
        font-family: 'Baloo Bhai', Sans-Serif;
        font-size: 3vw;
    }
    .location-name{
        font-family: 'Baloo Bhai', Sans-Serif;
        font-size:2vw;
    }
    .description{
        margin-top:0;
    }
    .artist-name{
        font-family: 'Special Elite', Sans-Serif;
        font-size:4vw;
        border-bottom:2px dotted black;
    }

    ${media.tablet`
        .title{
            padding-top:1rem;
            font-size:2rem;
        }
        .artist-name{
            font-size:1.5rem;
        }
        .header{
            font-size: 2vw;
        }
    `}
    ${media.desktop`
        .header{
            font-size: 1.5vw;
        }
    `}
    ${media.widescreen`
        .header{
            font-size: 1em;
        }
    `}
`