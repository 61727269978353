import React from 'react';
import { connect } from 'react-redux';
import {RotatingBeneficiariesSection} from './StyledComponents';
import RotatingBeneficiary from './RotatingBeneficiary';
import * as actions from 'app/redux/actions'

const RotatingBeneficiaries = ({rotatingBeneficiary}) => {
    const rotatingBeneficiaryLength = rotatingBeneficiary.length
    if(rotatingBeneficiary.length < 0) return <div/>

    return(
        <RotatingBeneficiariesSection id='RotatingBeneficiariesSection'>
            <h1 className="beneficiary-name">Rotating {rotatingBeneficiaryLength === 1 ? 'Beneficiary' :'Beneficiaries'}</h1>
            {rotatingBeneficiary.map((beneficiary,index)=><RotatingBeneficiary key={beneficiary.item_id} index={index} beneficiary={beneficiary}/>)}
        </RotatingBeneficiariesSection>
    )
}

export default connect(
    ({rotatingBeneficiary})=>({rotatingBeneficiary}),
    actions
)(RotatingBeneficiaries)