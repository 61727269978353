import React ,{useState} from 'react';
import {StyledMoreInfoModal} from './StyledComponents';
import history from 'app/history';
import Modal from 'app/components/reusable/Modal';
import SelectASizeCartButtons from '../InventorySection/ItemCard/SelectASizeCartButtons';
import { connect } from 'react-redux';
import * as actions from 'app/redux/actions'
import Checkout from '../Checkout';

const MoreInfoModal = ({item={}, cart, storeType}) => {
    const {
        imageLink=[],
        title,
        link,
        linkDescription,
        locationName,
        description,
        descriptionPt2,
        descriptionPt3,
        artistName,
        artistBio,
        locationWebsite,
        artistWebsite,
        beneficiaries,
        beneficiaryWebsites,
    } = item
    const [displayImage , setDisplayImage] = useState(imageLink[0])
    const [modal, setModal]= useState(false)

    if (!Object.entries(item).length) return <div/>

    const renderLink = (text, link, className) => {
        if(text && !link){
            return <span className={className}>{text}</span>
        }
        if(text && link) {
            return <a href={link}  rel="noopener noreferrer" target="_blank" className={className}><u>{text}</u></a>
        }
        if(!text && link) {
            return <a href={link}  rel="noopener noreferrer" target="_blank" className={className}><u>Location Website</u></a>
        }
        return <span/>
    }

    return(
        <Modal
            id={`inventoryModal`}
            isOpen={modal}
            onClose={()=>{setModal(!modal); history.push(`/${storeType}`)}}
            title='About'
        >
            <StyledMoreInfoModal>
                <img className='display-image' src={displayImage} alt={title + '1'}/>
                {imageLink.length > 1 &&
                    <div className='more-images'>
                        {imageLink.map((link, index)=><img className='sub-image' key={link + index} onClick={()=>setDisplayImage(link)} src={link} alt={title}/>)}
                    </div>
                }
                <SelectASizeCartButtons storeType={storeType} position='relative' hover={true} item={item} unitLabel=' each' qtys={cart[item.item_id] ? cart[item.item_id].qtys : 0} />
                {Object.keys(cart).length > 0 && <Checkout setModal={setModal} />}
                <div className='title'>{title}</div>
                <div className='artist-name'>Artist: {renderLink(artistName, artistWebsite)} </div>
                <p className='description'>{description}</p>
                {descriptionPt2 && <p className='descriptionPt2'>{descriptionPt2}</p>}
                {descriptionPt3 && <p className='descriptionPt3'>{descriptionPt3}</p>}
                {link && <p><a href={link} className='link' rel="noopener noreferrer" target="_blank"><u>{linkDescription}</u></a></p>}
                {beneficiaries && <div className='header'>Benefiting:
                    <ul>
                        {beneficiaries && beneficiaries.map((benficiary,index) => (
                            <li key={benficiary}>{renderLink(benficiary, beneficiaryWebsites[index])}</li>
                        ))}
                    </ul>
                </div>}
                {locationName && <div className='header'>Raffle Location:</div>}
                {renderLink(locationName, locationWebsite, 'header')}
                {artistBio && <div className='title'>About the Artist</div>}
                {artistBio && <p className='artistBio'>{artistBio}</p>}
            </StyledMoreInfoModal>
        </Modal>
    )
}

export default connect(
    ({cart})=>({cart}),
    actions
)(MoreInfoModal)