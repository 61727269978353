import React from 'react';
import {StyledRotatingBeneficiary} from './StyledComponents';

const RotatingBeneficiary = ({beneficiary:{name,about,imageLink,percentage,website},index}) => {
    return(
        <StyledRotatingBeneficiary reverse={(index+1)%2? false:true}>
            <div className="img-container">
                <img src={imageLink} alt={name}/>
            </div>
            <div className='about'>
                <h1><a href={website} rel="noopener noreferrer" target="_blank" >{name}</a></h1>
                {/* <h2>{percentage}%</h2> */}
                <p>{about}</p>
            </div>
        </StyledRotatingBeneficiary>
    )
}

export default RotatingBeneficiary