import React from 'react';
import { connect } from 'react-redux';
import StyledEventList from './Styled';
import SpinningLogo from 'app/components/reusable/SpinningLogo';
import Toggle from 'app/components/reusable/Toggle';
import List from './List';


class EventsList extends React.Component {
    state={registrationFilter:false}
    render(){
        if(this.props.events.notLoaded || !this.props.events.coords || !this.props.bounds) return <SpinningLogo />
        return (
            <StyledEventList>
                <Toggle labelSize={3.2} falsy="All Events" truthy="Register" onSubmit={registrationFilter => this.setState({registrationFilter})}/>
                <List {...this.props} registrationFilter={this.state.registrationFilter}/>
            </StyledEventList>
        )
    }
}
export default connect(state=>state)(EventsList)