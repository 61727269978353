import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/components/App';

import reducers from './app/redux/reducers';
import { Provider } from 'react-redux';
import { createStore , applyMiddleware } from 'redux';

import reduxThunk from 'redux-thunk';

import {unregister} from './serviceWorker';

unregister();

const store = createStore( reducers, {} , applyMiddleware(reduxThunk) );

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.querySelector('#root')
);
