import axios from 'axios';
import shuffle from 'app/helperJS/shuffle';

import { GET_VOLUNTEERS ,  GET_TEAM} from './types';

export const getVolunteers = () => async dispatch => {
    const res = await axios.get('/api/getvolunteers')
    dispatch({ type: GET_VOLUNTEERS, payload: shuffle(res.data) });
};

export const getTeam = () => async dispatch => {
    const res = await axios.get('/api/getteam')
    dispatch({ type: GET_TEAM, payload: res.data });
};