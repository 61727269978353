import React from 'react';
import ReactDom from 'react-dom';
import {Link} from 'react-router-dom';
import NavLinks from '../NavLinks';
import { StyledNavSideBar } from "./StyledComponents"

class NavSideBar extends React.Component {

	constructor(props){
		super(props);
		this.background = React.createRef();
		this.state = {
			fadeType: null ,
			display: 'none'
		};
	}

	onCloseNav = e => {
		e.preventDefault();
		this.setState({ fadeType: 'out' });
		window.removeEventListener('keydown', this.onEscKeyDown, false);

		const timeOut = this.props.transitionOutTime ? this.props.transitionOutTime * 1000 : 150;
		setTimeout(()=>{this.setState({ display: 'none' });},timeOut)
	};

	onEscKeyDown = e => {
		if (e.key !== 'Escape') return;
		this.setState({ fadeType: 'out' });
    };

	onToggle = () =>{
		this.setState({ display: 'initial' })
		window.addEventListener('keydown', this.onEscKeyDown, false);
		setTimeout(()=>{
			this.setState({ fadeType: 'in' })
		},50)
	}

	render() {
		return(
			<div style={{height:'100%', padding:'0px 8px' , maxHeight:'40px'}}>
				{ReactDom.createPortal(
					<StyledNavSideBar
						id='NavSideBar'
						display={this.state.display}
						onTransitionEnd={this.transitionEnd}
						transitionInTime={this.props.transitionInTime}
						transitionOutTime={this.props.transitionOutTime}
					>
						<div className={`nav-sidebar fade-${this.state.fadeType}`} >

							<div className="nav-header">
								<div className="nav-title">
									<Link to='/'>{this.props.title}</Link>
								</div>
							</div>
							<div className="nav-links" >
								<div className="nav-links-list">
									<div className="nav-links-main">
										<NavLinks onCloseNav={this.onCloseNav}/>
									</div>
								</div>
							</div>
							<div className="nav-footer">
							</div>

						</div>
						<div
							className={`background fade-${this.state.fadeType}`}
							onMouseDown={this.onCloseNav}
							ref={this.background}
						/>
					</StyledNavSideBar>,
					document.getElementById('modal-root')
				)}
				<img style={{height:'100%'}} className="nav-toggle-button" src="/imgs/hamburger_icon.svg" alt="Menu Icon" onClick={this.onToggle}/>
			</div>

		)

	}
}
export default NavSideBar;